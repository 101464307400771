import React, { useEffect, useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
// import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard';
import ProfilesList from 'examples/Lists/ProfilesList';
import Header from 'layouts/profile/components/Header';
import apiService from '../../services/ApiService';
import { AuthContext } from '../../context/AuthContext';
import { Business } from '@mui/icons-material';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import { Button, Card, CircularProgress } from '@mui/material';
import NewProfileCard from 'examples/Cards/InfoCards/NewProfileCard';
import FaceWeb from 'components/FaceWeb';
import toast from 'react-hot-toast';

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function Overview() {
  // const [profiles, setProfiles] = useState([]);
  const { user, permission } = useContext(AuthContext);
  console.log('user', user);
  const { Admin = false, HrManager = false, HRBP = false } = permission;
  const [showFaceDetection, setShowFaceDetection] = useState(false);
  const [faceImage, setFaceImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const handleRegisterClick = () => {
    setShowFaceDetection(true);
  };

  const handleUpdateProfile = async () => {
    const formData = new FormData();
    formData.append('fullName', user.fullName); // Full name
    formData.append('personNumber', user.personNumber); // Person number
    // formData.append('roles', JSON.stringify(user.roles)); // Roles (converted to JSON string)
    formData.append('isApproved', user.isApproved); // Approval status
    formData.append('declinedStatus', user.declinedStatus); // Declined status
    formData.append('password', user.password); // Password, if updating
    if (faceImage) {
      const file = dataURLtoFile(faceImage, 'capturedFace.jpg');
      formData.append('faceImage', file);
    }

    setIsLoading(true);
    try {
      const response = await apiService.put(`/api/v1/users/update/${user?._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure correct content type
        },
      });

      if (response.status === 200) {
        toast.success('User Successfully Updated');
        navigate('/dashboard');
        setIsLoading(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {Admin ? (
        <>
          <MDBox pt={6} pb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                    Profile Information
                  </MDTypography>
                </MDBox>
                <Card>
                  <MDBox pt={5} pb={3} px={5}>
                    <Grid container spacing={4}>
                      {/* Profile Image */}
                      <Grid item xs={12} md={4} lg={2} justifyContent="center">
                        <MDBox sx={{}}>
                          <MDTypography
                            style={{
                              marginBottom: '15px',
                            }}
                            variant="h6"
                            color="textPrimary"
                          >
                            Profile Image
                          </MDTypography>
                          <MDAvatar
                            src={`https://babtainnotify.com:81/${user.profileImage}`}
                            alt={user.fullName}
                            sx={{ width: 150, height: 150, boxShadow: 3 }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={8} lg={10}>
                        {/* <MDBox mb={2}>
                      {!showFaceDetection && (
                        <Button
                          onClick={handleRegisterClick}
                          style={{
                            backgroundColor: '#0e76ff',
                            color: 'white',
                          }}
                        >
                          Capture Face Image
                        </Button>
                      )}
                    </MDBox> */}
                        {/* {showFaceDetection && (
                      <FaceWeb
                        setFaceImage={setFaceImage}
                        isShown={isShown}
                        setIsShown={setIsShown}
                      />
                    )} */}
                        <Card>
                          <NewProfileCard
                            title="Profile Information"
                            info={{
                              fullName: user?.fullName || 'N/A',
                              email: user?.email || 'N/A',
                              personNumber: user?.personNumber || 'N/A',
                              createdAt: new Date(user?.createdAt).toLocaleDateString(),
                              department: user?.department?.name || 'N/A',
                              departmentLocation: user?.department?.address || 'N/A',
                              businessUnit: user?.businessUnit || 'N/A',
                              role: user?.roles?.map((role) => role.name).join(', ') || 'N/A',
                            }}
                            shadow={false}
                          />
                        </Card>

                        {/* <MDBox mt={4}>
                      {isShown && (
                        <Button
                          onClick={handleUpdateProfile}
                          style={{
                            backgroundColor: '#0e76ff',
                            color: 'white',
                          }}
                        >
                          {isLoading ? <CircularProgress color="inherit" /> : 'Update Profile'}
                        </Button>
                      )}
                    </MDBox> */}
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      ) : (
        <>
          <MDBox pt={6} pb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                    Profile Information
                  </MDTypography>
                </MDBox>
                <Card>
                  <MDBox pt={5} pb={3} px={5}>
                    <Grid container spacing={4}>
                      {/* Profile Image */}
                      <Grid item xs={12} md={4} lg={2} justifyContent="center">
                        <MDBox sx={{}}>
                          <MDTypography
                            style={{
                              marginBottom: '15px',
                            }}
                            variant="h6"
                            color="textPrimary"
                          >
                            Profile Image
                          </MDTypography>
                          <MDAvatar
                            src={`https://babtainnotify.com:81/${user.profileImage}`}
                            alt={user.fullName}
                            sx={{ width: 150, height: 150, boxShadow: 3 }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={8} lg={10}>
                        {/* <MDBox mb={2}>
                          {!showFaceDetection && (
                            <Button
                              onClick={handleRegisterClick}
                              style={{
                                backgroundColor: '#0e76ff',
                                color: 'white',
                              }}
                            >
                              Capture Face Image
                            </Button>
                          )}
                        </MDBox> */}
                        {/* {showFaceDetection && (
                          <FaceWeb
                            setFaceImage={setFaceImage}
                            isShown={isShown}
                            setIsShown={setIsShown}
                          />
                        )} */}
                        <Card>
                          <NewProfileCard
                            title="Profile Information"
                            info={{
                              fullName: user?.fullName || 'N/A',
                              email: user?.email || 'N/A',
                              personNumber: user?.personNumber || 'N/A',
                              createdAt: new Date(user?.createdAt).toLocaleDateString(),
                              department: user?.department?.name || 'N/A',
                              departmentLocation: user?.department?.address || 'N/A',
                              businessUnit: user?.businessUnit || 'N/A',
                              role: user?.roles?.map((role) => role.name).join(', ') || 'N/A',
                            }}
                            shadow={false}
                          />
                        </Card>

                        {/* <MDBox mt={4}>
                          {isShown && (
                            <Button
                              onClick={handleUpdateProfile}
                              style={{
                                backgroundColor: '#0e76ff',
                                color: 'white',
                              }}
                            >
                              {isLoading ? <CircularProgress color="inherit" /> : 'Update Profile'}
                            </Button>
                          )}
                        </MDBox> */}
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
}

export default Overview;
