import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

function Edit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    // description: "",
    // legalEntityNumber: "",
    // code: "",
    company: '',
  });
  console.log('formData', formData);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchBusinessUnit = async () => {
      try {
        const response = await apiService.get(`/api/v1/businessUnits/detail/${id}`);
        const unit = response.data.businessUnit;
        setFormData({
          name: unit.name || '',
          // description: unit.description || "",
          // legalEntityNumber: unit.legalEntityNumber || "",
          // code: unit.code || "",
          company: unit.company || '',
        });
      } catch (error) {
        toast.error('Failed to fetch business unit data');
      }
    };

    const fetchCompanies = async () => {
      try {
        const response = await apiService.get('/api/v1/companies/list');
        setCompanies(response.data.companies);
      } catch (error) {
        toast.error('Failed to fetch companies');
      }
    };

    fetchBusinessUnit();
    fetchCompanies();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.put(`/api/v1/businessUnits/update/${id}`, formData);
      if (response.status === 200) {
        navigate('/business-units');
        toast.success('Business Unit updated successfully');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to update business unit');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Update Business Unit
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={6} mt={-3}>
            <Card>
              <MDTypography pt={3} px={2} variant="h6" color="grey.700" fontWeight="medium">
                Update Business Unit
              </MDTypography>

              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Business Unit Name"
                    name="name"
                    variant="outlined"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </MDBox>

                <MDBox mb={2}>
                  <InputLabel id="company-label">Select Company</InputLabel>
                  <Select
                    labelId="company-label"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                    fullWidth
                    variant="standard"
                  >
                    {companies.map((company) => (
                      <MenuItem key={company._id} value={company._id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <Button
                    style={{
                      backgroundColor: '#c32033',
                      color: 'white',
                      padding: '1rem',
                    }}
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Edit;
