import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Card,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import AddIcon from '@mui/icons-material/AddBox';
import { DeleteOutline, Edit } from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import { AuthContext } from '../../context/AuthContext';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import apiService from '../../services/ApiService';
import { FadeLoader } from 'react-spinners';

function Users() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [roles, setRoles] = useState([]);
  const [isApproved, setIsApproved] = useState(false);
  const [allowFaceUpdate, setAllowFaceUpdate] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const { permission } = useContext(AuthContext);
  const { Admin = false } = permission;

  useEffect(() => {
    const fetchRoles = async () => {
      setLoading(true);
      try {
        const rolesResponse = await apiService.get('/api/v1/roles/list');
        if (rolesResponse.status === 200) {
          setRoles(rolesResponse.data.roles);
          setLoading(false);
        }
      } catch (error) {
        toast.error('Failed to fetch roles');
        setLoading(false);
      }
    };
    fetchRoles();
  }, []);

  // Fetch Users
  useEffect(() => {
    fetchUsersData();
  }, [roles, isApproved, allowFaceUpdate]);

  const fetchUsersData = async () => {
    try {
      const usersResponse = await apiService.get('/api/v1/users/list');
      if (usersResponse?.data?.status === 200) {
        const users = usersResponse.data.data;

        {
          Admin
            ? setColumns([
                { Header: 'Full Name', accessor: 'fullName', align: 'left' },
                { Header: 'Email', accessor: 'email', align: 'left' },
                { Header: 'Person No.', accessor: 'personNumber', align: 'left' },
                { Header: 'Department', accessor: 'department', align: 'left' },
                { Header: 'Update Face', accessor: 'allowFaceUpdate', align: 'center' },
                { Header: 'Roles', accessor: 'roles', align: 'left' },
                { Header: 'Approved', accessor: 'isApproved', align: 'center' },
                { Header: 'Action', accessor: 'action', align: 'center' },
              ])
            : setColumns([
                { Header: 'Full Name', accessor: 'fullName', align: 'left' },
                { Header: 'Email', accessor: 'email', align: 'left' },
                { Header: 'Person No.', accessor: 'personNumber', align: 'left' },
                { Header: 'Department', accessor: 'department', align: 'left' },
                { Header: 'Approved', accessor: 'isApproved', align: 'center' },
              ]);
        }

        const formattedRows = users.map((user) => ({
          id: user._id,
          fullName: (
            <Link to={`/users/view/${user._id}`}>
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.fullName || 'N/A'}
              </MDTypography>
            </Link>
          ),
          email: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.email}
            </MDTypography>
          ),
          personNumber: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.personNumber}
            </MDTypography>
          ),
          department: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user?.department?.name || 'N/A'}
            </MDTypography>
          ),
          allowFaceUpdate: (
            <Switch
              checked={user.allowFaceUpdate}
              onChange={() => handleToggleFace(user._id, !user.allowFaceUpdate)}
              color="primary"
            />
          ),
          roles: (
            <FormControl variant="outlined" size="small">
              <Select
                multiple
                value={user.roles.map((role) => role._id)}
                onChange={(e) => {
                  const selectedRoles = e.target.value;
                  const employeeRoleId = roles.find((role) => role.name === 'Employee')._id;

                  if (!selectedRoles.includes(employeeRoleId)) {
                    selectedRoles.push(employeeRoleId);
                  }

                  handleRoleChange(user._id, selectedRoles);
                }}
                renderValue={(selected) =>
                  selected.map((id) => roles.find((role) => role._id === id)?.name).join(', ')
                }
              >
                {roles.map((role) => (
                  <MenuItem key={role._id} value={role._id} disabled={role.name === 'Employee'}>
                    <Checkbox checked={user.roles.some((r) => r._id === role._id)} />
                    <ListItemText primary={role.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
          isApproved: (
            <Switch
              checked={user.isApproved}
              onChange={() => handleToggleApproval(user._id, !user.isApproved)}
              color="primary"
            />
          ),

          action: Admin ? (
            <MDBox display="flex" alignItems="center" justifyContent="space-between">
              <Link to={`/users/edit/${user._id}`}>
                <Edit fontSize="small" color="info" sx={{ cursor: 'pointer' }} />
              </Link>
              <MDBox mx={1} />
              <DeleteOutline
                fontSize="small"
                color="error"
                sx={{ cursor: 'pointer' }}
                onClick={() => openDeleteDialog(user._id)}
              />
            </MDBox>
          ) : null,
        }));

        setRows(formattedRows);
        setFilteredRows(formattedRows);
      }
    } catch (error) {
      toast.error('Failed to fetch users');
    }
  };

  const handleToggleApproval = async (userId, newStatus) => {
    try {
      await apiService.put(`/api/v1/users/update/${userId}`, { isApproved: newStatus });
      toast.success(newStatus ? 'User Approved.' : 'User Not Approved.');
      setIsApproved(newStatus);
    } catch (error) {
      toast.error('Failed to update user approval status');
    }
  };

  const handleToggleFace = async (userId, newStatus) => {
    console.log('newStatus', newStatus);
    try {
      await apiService.put(`/api/v1/users/allowFaceUpdate/${userId}`, { isUpdateFace: newStatus });
      toast.success('User Face Update Request Update.');
      setAllowFaceUpdate(newStatus);
    } catch (error) {
      toast.error('Failed to update user face update  status');
    }
  };

  const handleRoleChange = async (userId, newRoles) => {
    try {
      await apiService.put(`/api/v1/users/update/${userId}`, { roles: newRoles });
      toast.success('User roles updated successfully');

      const updatedRoles = newRoles
        .map((id) => roles.find((role) => role._id === id)?.name)
        .join(', ');

      setRows((prevRows) =>
        prevRows.map((row) =>
          row.id === userId
            ? {
                ...row,
                roles: (
                  <FormControl variant="outlined" size="small">
                    <Select
                      multiple
                      value={newRoles}
                      onChange={(e) => {
                        const selectedRoles = e.target.value;
                        const employeeRoleId = roles.find((role) => role.name === 'Employee')._id;

                        if (!selectedRoles.includes(employeeRoleId)) {
                          selectedRoles.push(employeeRoleId);
                        }

                        handleRoleChange(userId, selectedRoles);
                      }}
                      renderValue={(selected) =>
                        selected.map((id) => roles.find((role) => role._id === id)?.name).join(', ')
                      }
                    >
                      {roles.map((role) => (
                        <MenuItem
                          key={role._id}
                          value={role._id}
                          disabled={role.name === 'Employee'}
                        >
                          <Checkbox checked={newRoles.includes(role._id)} />
                          <ListItemText primary={role.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ),
              }
            : row
        )
      );
      setFilteredRows((prevRows) =>
        prevRows.map((row) =>
          row.id === userId
            ? {
                ...row,
                roles: (
                  <FormControl variant="outlined" size="small">
                    <Select
                      multiple
                      value={newRoles}
                      onChange={(e) => {
                        const selectedRoles = e.target.value;
                        const employeeRoleId = roles.find((role) => role.name === 'Employee')._id;

                        if (!selectedRoles.includes(employeeRoleId)) {
                          selectedRoles.push(employeeRoleId);
                        }

                        handleRoleChange(userId, selectedRoles);
                      }}
                      renderValue={(selected) =>
                        selected.map((id) => roles.find((role) => role._id === id)?.name).join(', ')
                      }
                    >
                      {roles.map((role) => (
                        <MenuItem
                          key={role._id}
                          value={role._id}
                          disabled={role.name === 'Employee'}
                        >
                          <Checkbox checked={newRoles.includes(role._id)} />
                          <ListItemText primary={role.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ),
              }
            : row
        )
      );
    } catch (error) {
      toast.error('Failed to update user roles');
    }
  };

  const openDeleteDialog = (userId) => {
    setUserToDelete(userId);
    setOpenDialog(true);
  };

  const closeDeleteDialog = () => {
    setUserToDelete(null);
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    try {
      await apiService.put(`/api/v1/users/delete/${userToDelete}`);
      toast.success('User deleted successfully');
      setRows((prevRows) => prevRows.filter((row) => row.id !== userToDelete));
      setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== userToDelete));
      closeDeleteDialog();
    } catch (error) {
      toast.error('Failed to delete user');
      closeDeleteDialog();
    }
  };

  const handleSearch = (e) => {
    setLoading(true); // Start loading when search begins

    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    setTimeout(() => {
      const newFilteredRows = rows.filter((row) => {
        const fullName = row.fullName.props.children.props.children?.toLowerCase() || '';
        const personNumber = row.personNumber.props.children?.toLowerCase() || '';
        return fullName.includes(query) || personNumber.includes(query);
      });

      setFilteredRows(newFilteredRows);
      setLoading(false); // Stop loading after filtering is done
    }, 500); // Simulate delay for better UX
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              mb={1}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Users Management
              </MDTypography>
              <Link to={'/users/create'}>
                <Button
                  variant="contained"
                  style={{ color: 'white', background: ' #c32033' }}
                  startIcon={<AddIcon style={{ color: 'white' }} />}
                >
                  Add New User
                </Button>
              </Link>
            </MDBox>
            <Card>
              <MDBox p={2} display="flex" justifyContent="flex-end">
                <TextField
                  label='Search by "Full Name" or "Person No."'
                  variant="outlined"
                  placeholder='Search by "Full Name" or "Person No."'
                  value={searchQuery}
                  onChange={handleSearch}
                  size="medium"
                  fullWidth
                  sx={{ maxWidth: '400px' }}
                />
              </MDBox>
              <MDBox pt={3}>
                {loading ? ( // Show loader while fetching data
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <FadeLoader color="#FF0000" />
                  </MDBox>
                ) : filteredRows.length > 0 ? (
                  <DataTable
                    table={{ columns, rows: filteredRows }}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={true}
                  />
                ) : (
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      No User Found
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={closeDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this user?</DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} sx={{ color: 'error.main' }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Users;
