import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: 'linear-gradient(195deg, #42424a, #191919)',
    backgroundColor: '#c32033',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [createData('Frozen yoghurt', 159)];

export default function TableCustomized({ attendanceLogs }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }} aria-label="customized table">
        <TableHead

          sx={{
            display: 'contents',
            width: '100%',

          }}
        >
          <TableRow>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="center">Time</StyledTableCell>
            <StyledTableCell align="center">Department</StyledTableCell>
            <StyledTableCell align="center">Shift Name</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attendanceLogs?.attendanceRecord?.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.status === 'checkIn' ? 'Checked In' : 'Checked Out'}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.time}
                {/* {new Date(row.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} */}
              </StyledTableCell>
              <StyledTableCell align="center">{attendanceLogs?.department}</StyledTableCell>
              <StyledTableCell align="center">{attendanceLogs?.shift?.shiftName}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
