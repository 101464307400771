import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';

function DepartmentDetail() {
  const { id } = useParams(); // Get department ID from the URL
  const [department, setDepartment] = useState(null);

  useEffect(() => {
    const fetchDepartmentDetails = async () => {
      try {
        const response = await apiService.get(`/api/v1/departments/detail/${id}`);
        if (response.status === 200) {
          setDepartment(response.data.department); // Assuming the API returns department under `department`
        }
      } catch (error) {
        toast.error('Failed to fetch department details');
      }
    };

    fetchDepartmentDetails();
  }, [id]);

  if (!department) return null; // Show nothing until the data is loaded

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Department Details
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox pt={5} pb={3} px={5}>
                <Grid container spacing={4}>
                  {/* Basic Department Info */}
                  <Grid item xs={12} md={8} lg={10}>
                    <Card>
                      <ProfileInfoCard
                        title="Basic Information"
                        info={{
                          Name: department?.name || 'N/A',
                          Address: department?.address || 'N/A',
                          Timezone: department?.timezone || 'N/A',
                          Radius: department?.radius || 'N/A',
                          'Location Status': department?.location_status || 'N/A',
                          'Latitude, Longitude': department?.location
                            ? `${department?.location?.lat}, ${department?.location?.lng}`
                            : 'N/A',
                          'Shift Name': department?.shift?.shiftName || 'N/A',
                          'Shift ID': department?.shift?._id || 'N/A',
                          'Hr Manager':
                            department?.hr_manager?.map((manager) => manager.name).join(', ') ||
                            'N/A',
                        }}
                        shadow={false}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default DepartmentDetail;
