import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import AddIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Edit, DeleteOutline } from '@mui/icons-material';
import { Button, MenuItem, Select } from '@mui/material';
import FormDialog from 'components/FormDialog';
import InformationDialog from 'components/InformationDialog';
import AssignManager from 'components/AssignManager';
import { AuthContext } from 'context/AuthContext';
import { FadeLoader } from 'react-spinners';

function Tables() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const { permission } = useContext(AuthContext);
  const { Admin, HrManager, HRBP } = permission;

  const fetchDepartmentsData = async () => {
    setLoading(true);
    try {
      const response = await apiService.get('/api/v1/departments/list');
      if (response.status === 200) {
        const departments = response.data.departments;
        {
          Admin
            ? setColumns([
                { Header: 'Name', accessor: 'name', align: 'left' },
                { Header: 'Get Location', accessor: 'location', align: 'left' },
                { Header: 'Location Status', accessor: 'location_status', align: 'left' },
                { Header: 'Information', accessor: 'information', align: 'left' },
                { Header: 'Assign Manager', accessor: 'manager', align: 'left' },
                { Header: 'Action', accessor: 'action', align: 'left' },
              ])
            : setColumns([
                { Header: 'Name', accessor: 'name', align: 'left' },
                { Header: 'Get Location', accessor: 'location', align: 'left' },
                { Header: 'Location Status', accessor: 'location_status', align: 'left' },
                { Header: 'Information', accessor: 'information', align: 'left' },
              ]);
        }

        const formattedRows = departments.map((department) => ({
          id: department._id,
          name: (
            <Link to={`/departments/${department._id}`}>
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {department.name || 'N/A'}
              </MDTypography>
            </Link>
          ),
          location: (
            <FormDialog
              fetchDepartmentsData={fetchDepartmentsData}
              departmentId={department._id}
              status={department.location_status}
            />
          ),
          location_status: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {department.location_status}
            </MDTypography>
          ),
          information: <InformationDialog emailLog={department?.emailLog} />,
          manager: Admin ? (
            <AssignManager department={department} fetchDepartmentsData={fetchDepartmentsData} />
          ) : null,

          action: Admin ? (
            <MDBox display="flex" alignItems="center" justifyContent="center">
              <Link to={`/departments/edit/${department._id}`}>
                <Edit fontSize="small" color="info" sx={{ cursor: 'pointer' }} />
              </Link>
              <MDBox mx={1} />
              <DeleteOutline
                fontSize="small"
                color="error"
                sx={{ cursor: 'pointer' }}
                onClick={() => handleDelete(department._id)}
              />
            </MDBox>
          ) : null,
        }));

        setRows(formattedRows);
        setFilteredRows(formattedRows); // Initialize filtered rows
        setLoading(false);
      }
    } catch (error) {
      toast.error('Failed to fetch departments data');
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch managers on component mount
    fetchDepartmentsData(); // Fetch departments data
  }, []);

  const handleDelete = async (departmentId) => {
    try {
      const response = await apiService.delete(`/api/v1/departments/delete/${departmentId}`);
      if (response.status === 200) {
        toast.success('Department deleted successfully');
        setRows((prevRows) => prevRows.filter((row) => row.id !== departmentId));
        setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== departmentId)); // Update filtered rows
      }
    } catch (error) {
      toast.error('Failed to delete department');
    }
  };

  // const handleAssignManager = async (departmentId, managerId) => {
  //   console.log('departmentId', departmentId);
  //   console.log('managerId', managerId);
  //   try {
  //     console.log('try----------------')
  //     const response = await apiService.patch(`/api/v1/departments/assignManager/${departmentId}`, {
  //       managerId,
  //     });
  //     if (response.status === 200) {
  //       toast.success('Manager assigned successfully');
  //       fetchDepartmentsData(); // Re-fetch the departments data to reflect changes
  //     }
  //   } catch (error) {
  //     toast.error('Failed to assign manager');
  //   }
  // };
  const handleSearch = (e) => {
    setLoading(true); // Start loading when search begins

    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    setTimeout(() => {
      const newFilteredRows = rows.filter((row) => {
        const name = row.name.props?.children?.props?.children?.toLowerCase() || '';
        return name.includes(query);
      });

      setFilteredRows(newFilteredRows);
      setLoading(false); // Stop loading after filtering is done
    }, 500); // Simulate delay for better UX
  };

  console.log('filteredRows', filteredRows);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              mb={1}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Departments
              </MDTypography>
              <Link to={'/departments/create'}>
                <Button
                  variant="contained"
                  style={{ color: 'white', background: ' #c32033' }}
                  startIcon={<AddIcon style={{ color: 'white' }} />}
                >
                  Add New Department
                </Button>
              </Link>
            </MDBox>
            <Card>
              <MDBox p={2} display="flex" justifyContent="flex-end">
                <TextField
                  label="Search by Department Name"
                  variant="outlined"
                  placeholder="Search by Department Name"
                  value={searchQuery}
                  onChange={handleSearch}
                  size="medium"
                  fullWidth
                  sx={{ maxWidth: '400px' }}
                />
              </MDBox>
              <MDBox pt={3}>
                {loading ? ( // Show loader while fetching data
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <FadeLoader color="#FF0000" />
                  </MDBox>
                ) : filteredRows.length > 0 ? (
                  <DataTable
                    table={{ columns, rows: filteredRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={true}
                  />
                ) : (
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      No Department Found
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
