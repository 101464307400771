// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import toast from "react-hot-toast";
// import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
// import MDButton from "components/MDButton";
// import BasicLayout from "layouts/authentication/components/BasicLayout";
// import bgImage from "assets/images/background.jpg";
// import apiService from "../../../services/ApiService";

// function Cover() {
//   const [user, setUser] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     employeeNo: "",
//     company: "",
//     businessUnit: "",
//     department: "",
//     password: "",
//     role: "Employee",
//   });

//   const [companies, setCompanies] = useState([]);
//   const [allBusinessUnits, setAllBusinessUnits] = useState([]);
//   const [filteredBusinessUnits, setFilteredBusinessUnits] = useState([]);
//   const [allDepartments, setAllDepartments] = useState([]);
//   const [filteredDepartments, setFilteredDepartments] = useState([]);

//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchDropdownData = async () => {
//       try {
//         const companiesResponse = await apiService.get("/api/v1/companies/list");
//         const businessUnitsResponse = await apiService.get("/api/v1/businessUnits/list");
//         const departmentsResponse = await apiService.get("/api/v1/departments/list");

//         setCompanies(companiesResponse.data.companies);
//         setAllBusinessUnits(businessUnitsResponse.data.businessUnits);
//         setAllDepartments(departmentsResponse.data.departments);
//       } catch (error) {
//         console.error("Error fetching dropdown data", error);
//         toast.error("Error fetching dropdown data");
//       }
//     };

//     fetchDropdownData();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     if (name === "company") {
//       // Filter business units based on selected company
//       const filteredBU = allBusinessUnits.filter(
//         (businessUnit) => businessUnit.company._id === value
//       );

//       // Reset businessUnit and department when a new company is selected
//       setUser((prevUser) => ({
//         ...prevUser,
//         company: value,
//         businessUnit: "",
//         department: "",
//       }));

//       setFilteredBusinessUnits(filteredBU);
//       setFilteredDepartments([]); // Reset Departments dropdown
//     } else if (name === "businessUnit") {
//       // Filter departments based on selected business unit and active status
//       const filteredDept = allDepartments.filter((department) =>
//         department.businessUnits.some((bu) => bu.businessUnit._id === value && bu.status === true)
//       );

//       // Reset the department when a new business unit is selected
//       setUser((prevUser) => ({
//         ...prevUser,
//         businessUnit: value,
//         department: "",
//       }));

//       setFilteredDepartments(filteredDept);
//     } else if (name === "department") {
//       // Update the state when a department is selected
//       setUser((prevUser) => ({
//         ...prevUser,
//         department: value,
//       }));
//     } else {
//       // For other fields, just update the state
//       setUser((prevUser) => ({
//         ...prevUser,
//         [name]: value,
//       }));
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await apiService.post("/api/v1/users/signUp", user);
//       if (response.status === 201) {
//         toast.success("User Successfully Signed Up");
//         navigate("/signIn");
//       }
//     } catch (error) {
//       const errorMessage = error.response?.data?.message || "Something went wrong";
//       toast.error(errorMessage);
//     }
//   };

//   return (
//     <BasicLayout image={bgImage}>
//       <Card>
//         <MDBox
//           variant="gradient"
//           bgColor="info"
//           borderRadius="lg"
//           coloredShadow="success"
//           mx={2}
//           mt={-2}
//           p={3}
//           mb={1}
//           textAlign="center"
//         >
//           <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
//             Join us Today
//           </MDTypography>

//           <MDTypography display="block" variant="button" color="white" my={1}>
//             Enter Your Personal Details To Register
//           </MDTypography>
//         </MDBox>
//         <MDBox pt={2} pb={3} px={3}>
//           <MDBox component="form" role="form" onSubmit={handleSubmit}>
//             <Grid container spacing={1}>
//               <Grid item xs={12} sm={12} md={6} lg={6}>
//                 <MDBox mb={2}>
//                   <MDInput
//                     type="text"
//                     label="First Name"
//                     name="firstName"
//                     value={user.firstName}
//                     onChange={handleChange}
//                     variant="standard"
//                     fullWidth
//                   />
//                 </MDBox>
//               </Grid>
//               <Grid item xs={12} sm={12} md={6} lg={6}>
//                 <MDBox mb={2}>
//                   <MDInput
//                     type="text"
//                     label="Last Name"
//                     name="lastName"
//                     value={user.lastName}
//                     onChange={handleChange}
//                     variant="standard"
//                     fullWidth
//                   />
//                 </MDBox>
//               </Grid>
//             </Grid>

//             <FormControl fullWidth variant="standard" margin="normal">
//               <InputLabel>Company</InputLabel>
//               <Select name="company" value={user.company} onChange={handleChange}>
//                 {companies.map((company) => (
//                   <MenuItem key={company._id} value={company._id}>
//                     {company.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>

//             <FormControl
//               fullWidth
//               variant="standard"
//               margin="normal"
//               disabled={!filteredBusinessUnits.length}
//             >
//               <InputLabel>Business Unit</InputLabel>
//               <Select
//                 name="businessUnit"
//                 value={user.businessUnit}
//                 onChange={handleChange}
//                 disabled={!filteredBusinessUnits.length}
//               >
//                 {filteredBusinessUnits.map((businessUnit) => (
//                   <MenuItem key={businessUnit._id} value={businessUnit._id}>
//                     {businessUnit.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>

//             <FormControl
//               fullWidth
//               variant="standard"
//               margin="normal"
//               disabled={!filteredDepartments.length}
//             >
//               <InputLabel>Department</InputLabel>
//               <Select
//                 name="department"
//                 value={user.department}
//                 onChange={handleChange}
//                 disabled={!filteredDepartments.length}
//               >
//                 {filteredDepartments.map((department) => (
//                   <MenuItem key={department._id} value={department._id}>
//                     {department.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>

//             <MDBox mb={2}>
//               <MDInput
//                 type="text"
//                 label="Employee Number"
//                 name="employeeNo"
//                 value={user.employeeNo}
//                 onChange={handleChange}
//                 variant="standard"
//                 fullWidth
//               />
//             </MDBox>
//             <MDBox mb={2}>
//               <MDInput
//                 type="email"
//                 label="Email"
//                 name="email"
//                 value={user.email}
//                 onChange={handleChange}
//                 variant="standard"
//                 fullWidth
//               />
//             </MDBox>
//             <MDBox mb={2}>
//               <MDInput
//                 type="password"
//                 label="Password"
//                 name="password"
//                 value={user.password}
//                 onChange={handleChange}
//                 variant="standard"
//                 fullWidth
//               />
//             </MDBox>

//             {/* Hidden input for role */}
//             <input type="hidden" name="role" value="Employee" />

//             <MDBox mt={4} mb={1}>
//               <MDButton variant="gradient" color="info" fullWidth type="submit">
//                 Sign Up
//               </MDButton>
//             </MDBox>
//             <MDBox mt={3} mb={1} textAlign="center">
//               <MDTypography variant="button" color="text">
//                 Already have an account?{" "}
//                 <MDTypography
//                   component={Link}
//                   to="/authentication/sign-in"
//                   variant="button"
//                   color="info"
//                   fontWeight="medium"
//                   textGradient
//                 >
//                   Sign In
//                 </MDTypography>
//               </MDTypography>
//             </MDBox>
//           </MDBox>
//         </MDBox>
//       </Card>
//     </BasicLayout>
//   );
// }

// export default Cover;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import bgImage from 'assets/images/background.jpg';
import apiService from '../../../services/ApiService';
import Button from '@mui/material/Button';
import FaceWeb from 'components/FaceWeb';
import { Box, CircularProgress, Typography } from '@mui/material';

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function Cover() {
  const [formData, setFormData] = useState({
    email: '',
    personNumber: '',
    password: '',
    profileImage: null,
    role: 'Employee',
  });
  console.log(formData);
  const [fileName, setFileName] = useState('');

  const navigate = useNavigate();
  const [showFaceDetection, setShowFaceDetection] = useState(false);
  const [faceImage, setFaceImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const handleRegisterClick = () => {
    setShowFaceDetection(true);
  };

  const handleChange = (e) => {
    const { name, type } = e.target;

    if (type === 'file') {
      const files = e.target.files; // Get the files from the input
      if (files.length > 0) {
        const file = files[0]; // Get the first file
        setFormData((prevData) => ({
          ...prevData,
          profileImage: file, // Update the state with the file
        }));
        setFileName(file.name); // Set the file name for display
      } else {
        // Clear the state if no file is selected
        setFormData((prevData) => ({
          ...prevData,
          profileImage: null,
        }));
        setFileName(''); // Clear the file name
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.personNumber || !formData.password || !formData.profileImage) {
      toast.error('All fields  are required.');
      return;
    }

    if (!faceImage) {
      toast.error('Face Image is required.');
      return;
    }

    const fd = new FormData();

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        // Only append file if it exists
        if (key === 'profileImage' && formData[key] instanceof File) {
          fd.append(key, formData[key], formData[key].name);
        } else {
          fd.append(key, formData[key]);
        }
      }
    }

    if (faceImage) {
      const file = dataURLtoFile(faceImage, 'capturedFace.jpg');
      fd.append('faceImage', file);
      // fd.append('capturedImage', file);
    }
    setIsLoading(true);
    try {
      const response = await apiService.post('/api/v1/users/registerUser', fd, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure correct content type
        },
      });

      if (response.status === 201) {
        toast.success('User Successfully Registered');
        navigate('/signIn');
        setIsLoading(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Something went wrong';
      toast.error(errorMessage);
      setIsLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <Box
          variant="gradient"
          bgcolor="#c32033"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us Today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter Your Personal Details To Register
          </MDTypography>
        </Box>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              {!showFaceDetection && (
                <Button
                  onClick={handleRegisterClick}
                  style={{
                    backgroundColor: '#c32033',
                    color: 'white',
                  }}
                >
                  Capture Face Image
                </Button>
              )}
            </MDBox>
            {showFaceDetection && (
              <FaceWeb
                setFaceImage={setFaceImage}
                isShown={isShown}
                setIsShown={setIsShown}
                isReset={true}
              />
            )}
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                variant="standard"
                fullWidth
                required
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Person Number"
                name="personNumber"
                value={formData.personNumber}
                onChange={handleChange}
                variant="standard"
                fullWidth
                required
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                variant="standard"
                fullWidth
                required
              />
            </MDBox>

            <MDBox mb={2}>
              <input
                type="file"
                name="profileImage"
                id="profileImage"
                accept="image/*"
                onChange={handleChange}
                style={{ display: 'none' }}
              />
              <label htmlFor="profileImage">
                <Button
                  style={{
                    backgroundColor: "#43A047",
                    color: 'white',
                    padding: '1rem',
                  }}
                  variant="contained"
                  color="info"
                  component="span"
                >
                  Choose Profile Image
                </Button>
              </label>
              <MDTypography variant="caption" color="textSecondary" sx={{ ml: 2 }}>
                {fileName || 'No file chosen'}
              </MDTypography>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <Button
                variant="gradient"
                disabled={isLoading}
                color="info"
                fullWidth
                type="submit"
                style={{
                  backgroundColor: '#c32033',
                  color: 'white',
                  padding: '1rem',
                }}
              >
                {isLoading ? <CircularProgress size={'30px'} color="inherit" /> : 'Sign Up'}
              </Button>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{' '}
                <Typography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="#c32033"
                  fontWeight="medium"
                  textTransform={'capitalize'}
                  textGradient
                >
                  Sign In
                </Typography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
