// import React, { useState, useContext, useEffect } from 'react';
// import Grid from '@mui/material/Grid';
// import Card from '@mui/material/Card';
// import Button from '@mui/material/Button';
// import MDBox from 'components/MDBox';
// import MDTypography from 'components/MDTypography';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import apiService from 'services/ApiService';
// import { AuthContext } from 'context/AuthContext';
// import moment from 'moment-timezone'; // Make sure this line is present
// import TableCustomized from 'components/TableCustomized';
// import {
//   Autocomplete,
//   CircularProgress,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField,
// } from '@mui/material';
// import MDButton from 'components/MDButton';
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
// import toast from 'react-hot-toast';
// import TableCustomizedUpdate from 'components/TableCustomizedUpdated';
// import AdminCustomized from 'components/AdminCustomized';
// import AttendanceAdmin from 'components/AttendanceAdmin';
// import { FadeLoader } from 'react-spinners';

// const timezone = 'Asia/Karachi'; // Set your desired timezone

// function AdminAttendance() {
//   const { user } = useContext(AuthContext);

//   const [dateData, setDateData] = useState({
//     startDate: dayjs(), // Initialize with the current date
//     endDate: dayjs(), // Initialize with the current date
//   });
//   const [departments, setDepartments] = useState([]);
//   const [departmentId, setDepartmentId] = useState('');

//   console.log('departmentId', departmentId);
//   const handleChange = () => {
//     setDepartmentId(event.target.value);
//   };

//   const handleDateChange = (field) => (newValue) => {
//     const updatedDate = dayjs(newValue);

//     setDateData((prevState) => {
//       if (field === 'startDate' && updatedDate.isAfter(prevState.endDate, 'day')) {
//         toast.error('Start Date cannot be after End Date');
//         return prevState;
//       }

//       if (field === 'endDate' && updatedDate.isBefore(prevState.startDate, 'day')) {
//         toast.error('End Date cannot be before Start Date');
//         return prevState;
//       }

//       return {
//         ...prevState,
//         [field]: updatedDate,
//       };
//     });
//   };

//   const [attendanceLogs, setAttendanceLogs] = useState();
//   const [isSuccess, setIsSuccess] = useState(false);

//   console.log('attendanceLogs', attendanceLogs);

//   const getDepartments = async () => {
//     try {
//       const response = await apiService.get('/api/v1/departments/list');
//       setDepartments(response?.data?.departments);
//     } catch (error) {
//       console.log('error', error);
//     }
//   };

//   useEffect(() => {
//     getDepartments();
//   }, []);

//   const [loading, setLoading] = useState(false);
//   const [message, setMessage] = useState('');

//   const handleGetData = async () => {
//     setLoading(true);

//     try {
//       let dataToSend = {
//         startDate: dayjs(dateData.startDate).format(),
//         endDate: dayjs(dateData.endDate).format(),
//       };
//       if (departmentId) {
//         dataToSend.departmentId = departmentId;
//       }
//       const response = await apiService.post('/api/v1/attendance/adminUserReport', dataToSend);
//       if (response.data.success) {
//         setAttendanceLogs(response.data.data);
//         setLoading(false);
//       }
//     } catch (error) {
//       console.error('Error fetching attendance logs:', error);
//       setLoading(false);
//       toast.error(`${error.response.data.message}`);
//     }
//   };
//   const isFormValid = dateData.startDate && dateData.endDate;

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox pt={6} pb={3}>
//         <Grid container spacing={6}>
//           <Grid item xs={12}>
//             <MDBox
//               mx={2}
//               mt={-3}
//               py={3}
//               display="flex"
//               alignItems="center"
//               justifyContent="space-between"
//             >
//               <MDTypography variant="h3" color="grey.700" fontWeight="medium">
//                 Attendance Report
//               </MDTypography>
//             </MDBox>
//             <Card>
//               <MDBox pt={3} px={3} mb={3}>
//                 {isSuccess ? (
//                   <MDBox>
//                     <MDTypography variant="h6" color="success">
//                       {message}
//                     </MDTypography>
//                   </MDBox>
//                 ) : (
//                   <MDTypography variant="h6" color="error">
//                     {message}
//                   </MDTypography>
//                 )}

//                 <Grid container spacing={3}>
//                   <Grid item xs={12} md={3}>
//                     <LocalizationProvider dateAdapter={AdapterDayjs}>
//                       <DatePicker
//                         label="Start Date"
//                         value={dateData.startDate}
//                         onChange={handleDateChange('startDate')}
//                         fullWidth
//                         renderInput={(params) => (
//                           <TextField
//                             {...params}
//                             sx={{
//                               '& .MuiInputBase-root': {
//                                 padding: '4px', // Adjust padding inside the input
//                               },
//                               width: '100%',
//                             }}
//                           />
//                         )}
//                       />
//                     </LocalizationProvider>
//                   </Grid>

//                   <Grid item xs={12} md={3}>
//                     <LocalizationProvider dateAdapter={AdapterDayjs}>
//                       <DatePicker
//                         label="End Date"
//                         value={dateData.endDate}
//                         onChange={handleDateChange('endDate')}
//                         fullWidth
//                         renderInput={(params) => (
//                           <TextField
//                             {...params}
//                             sx={{
//                               '& .MuiInputBase-root': {
//                                 padding: '4px', // Adjust padding inside the input
//                               },
//                               width: '100%',
//                             }}
//                           />
//                         )}
//                       />
//                     </LocalizationProvider>
//                   </Grid>

//                   <Grid item xs={12} md={3}>
//                     <Autocomplete
//                       options={departments}
//                       getOptionLabel={(option) => option.name} // Display department name
//                       onChange={(event, newValue) => setDepartmentId(newValue?._id || '')} // Update departmentId
//                       renderInput={(params) => (
//                         <TextField {...params} label="Select Department" variant="outlined" />
//                       )}
//                     />
//                   </Grid>

//                   <Grid item xs={12} md={3}>
//                     <Button
//                       disabled={!isFormValid}
//                       style={{
//                         backgroundColor: '#c32033',
//                         color: 'white',
//                         padding: '1rem',
//                       }}
//                       onClick={() => handleGetData()}
//                       // disabled={!attendanceStatus.checkIn || attendanceStatus.checkOut || loading}
//                       fullWidth
//                     >
//                       {loading ? (
//                         <CircularProgress color="white" size={'25px'} />
//                       ) : (
//                         'Search'
//                       )}
//                     </Button>
//                   </Grid>
//                 </Grid>

//                 {/* {attendanceLogs?.report?.length === 0 || attendanceLogs === undefined ? (
//                   <MDTypography
//                     sx={{
//                       m: 10,
//                     }}
//                     variant="h6"
//                     color="secondary"
//                   >
//                     No Logs found
//                   </MDTypography>
//                 ) : (
//                   <MDBox mt={3}>
//                     <AttendanceAdmin attendanceLogs={attendanceLogs} />
//                   </MDBox>
//                 )} */}
//               </MDBox>
//             </Card>
//             {loading ? (
//               <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
//                 <FadeLoader color="#FF0000" />
//               </MDBox>
//             ) : attendanceLogs?.report?.length === 0 || attendanceLogs === undefined ? (
//               <MDTypography
//                 sx={{
//                   m: 6,
//                   textAlign: 'center',
//                 }}
//                 variant="h6"
//                 color="secondary"
//               >
//                 No Logs found
//               </MDTypography>
//             ) : (
//               <MDBox mt={1} mb={1}>
//                 <AttendanceAdmin attendanceLogs={attendanceLogs} />
//               </MDBox>
//             )}
//           </Grid>
//         </Grid>
//       </MDBox>
//     </DashboardLayout>
//   );
// }

// export default AdminAttendance;

import React, { useState, useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from 'services/ApiService';
import { AuthContext } from 'context/AuthContext';
import moment from 'moment-timezone'; // Make sure this line is present
import TableCustomized from 'components/TableCustomized';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import MDButton from 'components/MDButton';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import TableCustomizedUpdate from 'components/TableCustomizedUpdated';
import AdminCustomized from 'components/AdminCustomized';
import AttendanceAdmin from 'components/AttendanceAdmin';
import { FadeLoader } from 'react-spinners';

const timezone = 'Asia/Karachi'; // Set your desired timezone

function AdminAttendance() {
  const { user } = useContext(AuthContext);

  const [dateData, setDateData] = useState({
    startDate: dayjs(), // Initialize with the current date
    endDate: dayjs(), // Initialize with the current date
  });
  const [departments, setDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState('');
  const [businessUnits, setBusinessUnits] = useState([]);
  const [businessUnitId, setBusinessUnitId] = useState('');
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUserByName, setSelectedUserByName] = useState(null);
  const [selectedUserByEmpNo, setSelectedUserByEmpNo] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState('');

  const getUsers = async () => {
    try {
      const response = await apiService.get('/api/v1/users/list');

      setUsers(response?.data?.data);
      setFilteredUsers(response?.data?.data); // Initially, show all users
    } catch (error) {
      console.log('Error fetching users:', error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (departmentId) {
      setFilteredUsers(users.filter((user) => user.department?._id === departmentId));
    } else {
      setFilteredUsers(users);
    }
  }, [departmentId, users]);
  const handleChange = () => {
    setDepartmentId(event.target.value);
  };

  const handleDateChange = (field) => (newValue) => {
    const updatedDate = dayjs(newValue);

    setDateData((prevState) => {
      if (field === 'startDate' && updatedDate.isAfter(prevState.endDate, 'day')) {
        toast.error('Start Date cannot be after End Date');
        return prevState;
      }

      if (field === 'endDate' && updatedDate.isBefore(prevState.startDate, 'day')) {
        toast.error('End Date cannot be before Start Date');
        return prevState;
      }

      return {
        ...prevState,
        [field]: updatedDate,
      };
    });
  };

  const [attendanceLogs, setAttendanceLogs] = useState();
  const [isSuccess, setIsSuccess] = useState(false);

  // depsrtment filter

  const filterDepartments = (departments, selectedBusinessUnit) => {
    if (!selectedBusinessUnit) {
      return departments; // Return all if no business unit is selected
    }
    return departments.filter((dept) =>
      dept.businessUnits.some((bu) => bu?.businessUnit?._id === selectedBusinessUnit)
    );
  };

  const getDepartments = async () => {
    try {
      const response = await apiService.get('/api/v1/departments/list');
      setDepartments(response?.data?.departments);
      setFilteredDepartments(response?.data?.departments);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    setFilteredDepartments(filterDepartments(departments, businessUnitId));
  }, [businessUnitId, departments]);

  // get business units

  const getBusinessUnits = async () => {
    try {
      const response = await apiService.get('/api/v1/businessUnits/list');
      setBusinessUnits(response?.data?.businessUnits);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getBusinessUnits();
  }, []);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleGetData = async () => {
    setLoading(true);

    try {
      let dataToSend = {
        startDate: dayjs(dateData.startDate).format(),
        endDate: dayjs(dateData.endDate).format(),
      };
      if (departmentId) {
        dataToSend.departmentId = departmentId;
      }
      if (businessUnitId) {
        dataToSend.businessId = businessUnitId;
      }

      if (selectedUserId) {
        dataToSend.userId = selectedUserId;
      }
      const response = await apiService.post('/api/v1/attendance/adminUserReport', dataToSend);
      if (response.data.success) {
        setAttendanceLogs(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching attendance logs:', error);
      setLoading(false);
      toast.error(`${error.response.data.message}`);
    }
  };
  const isFormValid = dateData.startDate && dateData.endDate;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Attendance Report
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox pt={3} px={3} mb={3}>
                {isSuccess ? (
                  <MDBox>
                    <MDTypography variant="h6" color="success">
                      {message}
                    </MDTypography>
                  </MDBox>
                ) : (
                  <MDTypography variant="h6" color="error">
                    {message}
                  </MDTypography>
                )}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={1.5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={dateData.startDate}
                        fullWidth
                        onChange={handleDateChange('startDate')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              '& .MuiInputBase-root': {
                                padding: '4px', // Adjust padding inside the input
                              },
                              width: '100%',
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} md={1.5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={dateData.endDate}
                        fullWidth
                        onChange={handleDateChange('endDate')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              '& .MuiInputBase-root': {
                                padding: '4px', // Adjust padding inside the input
                              },
                              width: '100%',
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      options={businessUnits}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => setBusinessUnitId(newValue?._id || '')}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Business Unit" variant="outlined" />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      options={filteredDepartments}
                      getOptionLabel={(option) => option.name} // Display department name
                      onChange={(event, newValue) => setDepartmentId(newValue?._id || '')} // Update departmentId
                      renderInput={(params) => (
                        <TextField {...params} label="Select Department" variant="outlined" />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      options={filteredUsers}
                      getOptionLabel={(option) => option.fullName}
                      value={selectedUserByName}
                      onChange={(event, newValue) => {
                        setSelectedUserByName(newValue);
                        setSelectedUserByEmpNo(null); // Reset Emp No selection
                        setSelectedUserId(newValue?._id || '');
                      }}
                      disabled={Boolean(selectedUserByEmpNo)} // Disable if Emp No is selected
                      renderInput={(params) => (
                        <TextField {...params} label="Select User By Name" variant="outlined" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      options={filteredUsers}
                      getOptionLabel={(option) => option.personNumber}
                      value={selectedUserByEmpNo}
                      onChange={(event, newValue) => {
                        setSelectedUserByEmpNo(newValue);
                        setSelectedUserByName(null); // Reset Name selection
                        setSelectedUserId(newValue?._id || '');
                      }}
                      disabled={Boolean(selectedUserByName)} // Disable if Name is selected
                      renderInput={(params) => (
                        <TextField {...params} label="Select User By Emp No" variant="outlined" />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <Button
                      disabled={!isFormValid}
                      style={{
                        backgroundColor: '#c32033',
                        color: 'white',
                        padding: '1rem',
                      }}
                      onClick={() => handleGetData()}
                      fullWidth
                    >
                      {loading ? <CircularProgress color="white" size={'25px'} /> : 'Search'}
                    </Button>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            {loading ? (
              <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                <FadeLoader color="#FF0000" />
              </MDBox>
            ) : attendanceLogs?.report?.length === 0 || attendanceLogs === undefined ? (
              <MDTypography
                sx={{
                  m: 6,
                  textAlign: 'center',
                }}
                variant="h6"
                color="secondary"
              >
                No Logs found
              </MDTypography>
            ) : (
              <MDBox mt={1} mb={1}>
                <AttendanceAdmin attendanceLogs={attendanceLogs} />
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AdminAttendance;
