import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import ProfileInfoCard from 'examples/Cards/InfoCards/ProfileInfoCard';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';

function ImportedDetail() {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await apiService.get(`/api/v1/importUsers/detail/${id}`);
        if (response.status === 200) {
          setUser(response.data.data);
        }
      } catch (error) {
        toast.error('Failed to fetch user details');
      }
    };
    fetchUserDetails();
  }, [id]);

  if (!user) return null;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Imported User Profile
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox pt={5} pb={3} px={5}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={8} lg={10}>
                    <Card>
                      <ProfileInfoCard
                        title="Profile Information"
                        info={{
                          fullName: user?.full_name || 'N/A',
                          email: user?.email || 'N/A',
                          personNumber: user?.person_number || 'N/A',
                          legalEmployer: user?.legal_employer || 'N/A',
                          businessUnit: user?.business_unit || 'N/A',
                          department: user?.department || 'N/A',
                          departmentCode: user?.department_code || 'N/A',
                          managerName: user?.manager_name || 'N/A',
                          managerEmail: user?.manager_email || 'N/A',
                          position: user?.position || 'N/A',
                        }}
                        shadow={false}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ImportedDetail;
