import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import { FadeLoader } from 'react-spinners';

function Roles() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRolesData = async () => {
      setLoading(true);
      try {
        const response = await apiService.get('/api/v1/roles/list');
        if (response.status === 200) {
          const roles = response.data.roles;

          setColumns([
            { Header: 'Role Name', accessor: 'name', width: '50%', align: 'left' },
            { Header: 'Created At', accessor: 'createdAt', align: 'left' },
            { Header: 'Action', accessor: 'action', align: 'left' },
          ]);

          const formattedRows = roles.map((role) => ({
            id: role._id,
            name: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {role.name}
              </MDTypography>
            ),
            createdAt: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {new Date(role.createdAt).toLocaleDateString()}
              </MDTypography>
            ),
            action: (
              <MDBox ml={-2} display="flex" alignItems="center" justifyContent="center">
                <Link to={`/roles/edit/${role._id}`}>
                  <Edit fontSize="small" color="info" sx={{ cursor: 'pointer' }} />
                </Link>
                <MDBox mx={1} />
                <DeleteOutline
                  fontSize="small"
                  color="error"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleDelete(role._id)}
                />
              </MDBox>
            ),
          }));

          setRows(formattedRows);
          setFilteredRows(formattedRows);
          setLoading(false);
        }
      } catch (error) {
        toast.error('Failed to fetch roles data');
        setLoading(false);
      }
    };

    fetchRolesData();
  }, []);

  const handleDelete = async (roleId) => {
    try {
      const response = await apiService.delete(`/api/v1/roles/delete/${roleId}`);
      if (response.status === 200) {
        toast.success('Role deleted successfully');
        setRows((prevRows) => prevRows.filter((row) => row.id !== roleId));
        setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== roleId));
      }
    } catch (error) {
      toast.error('Failed to delete role');
    }
  };

  const handleSearch = (e) => {
    setLoading(true); // Start loading when search begins

    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    setTimeout(() => {
      const newFilteredRows = rows.filter((row) => {
        const name = row.name.props?.children?.toLowerCase() || '';
        return name.includes(query);
      });

      setFilteredRows(newFilteredRows);
      setLoading(false); // Stop loading after filtering is done
    }, 500); // Simulate delay for better UX
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              mb={1}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Roles Management
              </MDTypography>
              <Link to={'/roles/create'}>
                <Button
                  variant="contained"
                  style={{ color: 'white', background: ' #c32033' }}
                  startIcon={<AddIcon style={{ color: 'white' }} />}
                >
                  Add New Roles
                </Button>
              </Link>
            </MDBox>
            <Card>
              <MDBox p={2} display="flex" justifyContent="flex-end">
                <TextField
                  label="Search by Role Name"
                  variant="outlined"
                  placeholder="Search by Role Name"
                  value={searchQuery}
                  onChange={handleSearch}
                  size="medium"
                  fullWidth
                  sx={{ maxWidth: '400px' }}
                />
              </MDBox>
              <MDBox pt={3}>
                {loading ? ( // Show loader while fetching data
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <FadeLoader color="#FF0000" />
                  </MDBox>
                ) : filteredRows.length > 0 ? (
                  <DataTable
                    table={{ columns, rows: filteredRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={true}
                  />
                ) : (
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      No Role Found
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Roles;
