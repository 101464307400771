import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import apiService from '../../services/ApiService';
import { useNavigate } from 'react-router-dom';
import { Button, InputLabel } from '@mui/material';
import toast from 'react-hot-toast';

const containerStyle = {
  width: '100%',
  height: '400px',
};

function Create() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    // description: "",
    // code: "",
    businessUnits: [],
    location: {
      lat: 0,
      lng: 0,
    },
    radius: '',
    shiftId: 0,
  });

  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({ lat: 0, lng: 0 }); // Default values
  const [shifts, setShifts] = useState([]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDctfNdridCgXmF6tQw4ef1tkcteV7VT7A',
  });


  

  useEffect(() => {
    // Fetch business unit options
    const fetchOptions = async () => {
      try {
        const businessUnitsResponse = await apiService.get('/api/v1/businessUnits/list');
        if (businessUnitsResponse.status === 200) {
          setBusinessUnitOptions(
            businessUnitsResponse.data.businessUnits.map((unit) => ({
              value: unit._id,
              label: unit.name,
            }))
          );
        }
      } catch (error) {
        console.error('Failed to fetch options:', error);
      }
    };

    // Get current location and fetch business units
    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setSelectedLocation({ lat: latitude, lng: longitude });
            setFormData((prevData) => ({
              ...prevData,
              location: { lat: latitude, lng: longitude },
            }));
          },
          (error) => {
            console.error('Error getting current location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    fetchOptions();
    getCurrentLocation();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBusinessUnitChange = (index, field, value) => {
    const updatedBusinessUnits = formData.businessUnits.map((businessUnit, i) => {
      if (i === index) {
        return { ...businessUnit, [field]: value };
      }
      return businessUnit;
    });
    setFormData({ ...formData, businessUnits: updatedBusinessUnits });
  };

  const addBusinessUnit = () => {
    setFormData({
      ...formData,
      businessUnits: [...formData.businessUnits, { businessUnit: '', status: true }],
    });
  };

  const removeBusinessUnit = (index) => {
    setFormData({
      ...formData,
      businessUnits: formData.businessUnits.filter((_, i) => i !== index),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.post('/api/v1/departments/create', formData);
      console.log('response', response)
      if (response.data.status === 200) {
        navigate('/departments');
        toast.success('Department created successfully');
        console.log('Department created successfully:', response.data);
      }
    } catch (error) {
      console.error('Failed to create department:', error);
    }
  };

  const onMapClick = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setSelectedLocation({ lat, lng });
    setFormData((prevData) => ({
      ...prevData,
      location: { lat, lng },
    }));
  }, []);

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setSelectedLocation({ lat: latitude, lng: longitude });
          setFormData((prevData) => ({
            ...prevData,
            location: { lat: latitude, lng: longitude },
          }));
        },
        (error) => {
          console.error('Error getting current location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const getShifts = async () => {
    try {
      const response = await apiService.get('/api/v1/shifts/list');

      if (response.status === 200) {
        setShifts(response.data.shifts);
      }
    } catch (error) {
      toast.error('Failed to fetch shifts');
      console.error('Failed to fetch shifts:', error);
    }
  };

  useEffect(() => {
    getShifts();
  }, []);

  console.log('shifts', shifts);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Create Department
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={6} mt={-3}>
            <Card>
              <MDTypography pt={3} px={2} variant="h6" color="grey.700" fontWeight="medium">
                Create Department
              </MDTypography>
              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Department Name"
                    name="name"
                    variant="standard"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </MDBox>

                <MDBox mb={2}>
                  <MDInput
                    type="number"
                    label="Radius (meters)"
                    name="radius"
                    variant="standard"
                    fullWidth
                    value={formData.radius}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <InputLabel id="shift-id-label">Select Shift *</InputLabel>
                  <Select
                    labelId="shift-id-label"
                    name="shiftId"
                    value={formData.shiftId}
                    onChange={handleInputChange}
                    fullWidth
                    variant="standard"
                  >
                    {shifts.map((shift) => (
                      <MenuItem key={shift.shiftName} value={shift.shiftId}>
                        {shift.shiftName}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>

                <MDBox mb={2}>
                  <MDTypography variant="h6" mb={1}>
                    Business Units
                  </MDTypography>
                  {formData.businessUnits.map((businessUnit, index) => (
                    <MDBox key={index} display="flex" alignItems="center" mb={1}>
                      <FormControl fullWidth variant="standard" sx={{ mr: 2 }}>
                        <Select
                          value={businessUnit.businessUnit}
                          onChange={(e) =>
                            handleBusinessUnitChange(index, 'businessUnit', e.target.value)
                          }
                        >
                          {businessUnitOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={businessUnit.status}
                            onChange={(e) =>
                              handleBusinessUnitChange(index, 'status', e.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label="Active"
                      />
                      <MDButton
                        variant="text"
                        color="error"
                        onClick={() => removeBusinessUnit(index)}
                      >
                        Remove
                      </MDButton>
                    </MDBox>
                  ))}
                  <MDButton variant="text" color="info" onClick={addBusinessUnit}>
                    Add Business Unit
                  </MDButton>
                </MDBox>

                <MDBox mb={2}>
                  <MDTypography variant="h6">Select Location</MDTypography>
                  {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={selectedLocation}
                      zoom={10}
                      onClick={onMapClick}
                    >
                      {selectedLocation && <Marker position={selectedLocation} />}
                    </GoogleMap>
                  ) : (
                    <MDTypography>Loading Map...</MDTypography>
                  )}
                  <MDBox mt={2}>
                    <MDButton variant="outlined" color="info" onClick={handleGetCurrentLocation}>
                      Use Current Location
                    </MDButton>
                  </MDBox>
                </MDBox>

                <MDBox mt={4} mb={1}>
                  <Button
                    style={{
                      backgroundColor: '#c32033',
                      color: 'white',
                      padding: '1rem',
                    }}
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    Create
                  </Button>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Create;
