import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard';
import ReportsBarChart from 'examples/Charts/BarCharts/ReportsBarChart';
import ReportsLineChart from 'examples/Charts/LineCharts/ReportsLineChart';
import Projects from 'layouts/dashboard/components/Projects';
import OrdersOverview from 'layouts/dashboard/components/OrdersOverview';
import apiService from '../../services/ApiService';

// Data for charts
import reportsBarChartData from 'layouts/dashboard/data/reportsBarChartData';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import Statuses from 'layouts/statuses';
import { AuthContext } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const navigate = useNavigate();
  const { sales, tasks } = reportsLineChartData;
  const { user, permission } = useContext(AuthContext);
  const {
    Admin = false,
    HrManager = false,
    HRBP = false,
    ItAdmin = false,
    Employee = false,
  } = permission;

  const [companyCount, setCompanyCount] = useState(0);
  const [businessUnitCount, setBusinessUnitCount] = useState(0);
  const [departmentCount, setDepartmentCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [attendanceStatus, setAttendanceStatus] = useState([]);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const [companyRes, businessUnitRes, departmentRes, userRes] = await Promise.all([
          apiService.get('/api/v1/companies/list'),
          apiService.get('/api/v1/businessUnits/list'),
          apiService.get('/api/v1/departments/list'),
          apiService.get('/api/v1/users/list'),
        ]);

        setCompanyCount(companyRes.data.companies.length);
        setBusinessUnitCount(businessUnitRes.data.businessUnits.length);
        setDepartmentCount(departmentRes.data.departments.length);
        setUserCount(userRes.data.data.length);
      } catch (error) {
        console.error('Failed to fetch counts:', error);
      }
    };

    fetchCounts();
  }, []);

  //get attendance status count

  useEffect(() => {
    const getAttendanceStatus = async () => {
      try {
        const response = await apiService.get('/api/v1/attendance/statusCount');
        const { attendanceStatusCount } = response?.data?.data;
        setAttendanceStatus(attendanceStatusCount);
      } catch (error) {
        console.error('Failed to fetch attendance status:', error);
      }
    };

    getAttendanceStatus();
  }, []);

  const handleCardClick = (status) => {
    const statusLower = status.toLowerCase();

    let path = ''; // Default path

    // Handle specific cases
    if (statusLower === 'present') {
      path = '/present-attendance';
    } else if (statusLower === 'late') {
      path = '/late-attendance';
    } else if (statusLower.includes('absent')) {
      path = '/absent-attendance';
    } else if (statusLower.includes('presentandearlycheckout')) {
      path = '/earlycheckout-attendance';
    } else if (statusLower.includes('lateandearlycheckout')) {
      path = '/lateearly-attendance';
    } else if (statusLower === 'companies') {
      path = '/companies';
    } else if (statusLower === 'business') {
      path = '/business-units';
    } else if (statusLower === 'departments') {
      path = '/departments';
    } else if (statusLower === 'users') {
      path = '/users';
    }

    navigate(path);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {Employee && !HrManager && !Admin && !HRBP && !ItAdmin ? (
        <MDBox py={3}>
          <h1 style={{ textAlign: 'center', color: '#1A73E8', fontWeight: 'bold' }}>
            Welcome to Babtify!
          </h1>
          <p style={{ textAlign: 'center', color: '#888' }}>We’re glad to have you here.</p>
        </MDBox>
      ) : Employee && (HrManager || HRBP || ItAdmin) ? (
        // Employee with additional roles (HRManager, Admin, HRBP, or ItAdmin)
        <MDBox mt={3}>
          <MDBox py={3}>
            <MDBox>
              <MDBox py={3}>
                <Grid container spacing={2}>
                  {attendanceStatus.map((status, index) => {
                    const getStatusProps = (status) => {
                      switch (status.status.toLowerCase()) {
                        case 'present':
                          return { color: 'success', icon: 'check_circle',background:"#3B82F6" , title:"Present Users" };
                        case 'absent':
                          return { color: 'error', icon: 'cancel', background:"#FF0000", title:"Absent Users" };
                        case 'late':
                          return { color: 'warning', icon: 'access_time' , background:"#FF9800" , title:"Late Users" };
                        case 'lateandearlycheckout':
                          return { color: 'info', icon: 'event' , background:"#4CAF50" , title:"Late and Early Checkout Users" };
                        case 'presentandearlycheckout':
                          return { color: 'dark', icon: 'hourglass_empty' , background:"#2C2C2C" , title:"Early Checkout Users" };
                        default:
                          return { color: 'default', icon: 'help_outline' };
                      }
                    };

                    const { color, icon,background,title } = getStatusProps(status);

                    return (
                      <Grid key={index} item xs={12} md={6} lg={index === 0 ? 2 : 2.5}>
                        <MDBox mb={1.5}>
                          <ComplexStatisticsCard
                            color={color}
                            icon={icon}
                            background={background}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleCardClick(status.status)}
                            title={title}
                            count={status.count}
                          />
                        </MDBox>
                      </Grid>
                    );
                  })}
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>

          <MDBox mt={3}>
            <Statuses />
          </MDBox>
        </MDBox>
      ) : Admin ? (
        <>
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="business"
                    title="Companies"
                    background="#3B82F6"
                    count={companyCount}
                    onClick={() => handleCardClick('companies')}
                    // percentage={{
                    //   color: "success",
                    //   amount: "+55%",
                    //   label: "than last week",
                    // }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="apartment"
                    title="Business Units"
                    background="#4CAF50"
                    count={businessUnitCount}
                    onClick={() => handleCardClick('business')}
                    // percentage={{
                    //   color: "success",
                    //   amount: "+3%",
                    //   label: "than last month",
                    // }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon="account_tree"
                    background="#FF9800"
                    title="Departments"
                    onClick={() => handleCardClick('departments')}
                    count={departmentCount}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="people"
                    background="#2C2C2C"
                    title="Total Users"
                    count={userCount}
                    onClick={() => handleCardClick('users')}
                   
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox mt={3}>
            <Statuses />
          </MDBox>
        </>
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
