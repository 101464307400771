import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Privacy Policy</h1>

      <h2>1. Introduction</h2>
      <p>
        Welcome to the Babtain Remote Attendance App. This platform streamlines attendance processes
        through face recognition and location-based technologies. Your privacy is important to us,
        and this Privacy Policy explains how we collect, use, share, and protect your information
        when you use our services.
      </p>

      <h2>2. Information We Collect</h2>
      <ul>
        <li>
          <strong>Personal Information:</strong> Includes your name, employee ID, department, and
          contact details provided during registration.
        </li>
        <li>
          <strong>Biometric Data:</strong> Facial recognition data for verifying attendance.
        </li>
        <li>
          <strong>Location Data:</strong> Your geographic location to confirm attendance eligibility
          within a specified radius of your department.
        </li>
        <li>
          <strong>Attendance Records:</strong> Logs of attendance, including timestamps, attendance
          status, and historical records that you can view within the app.
        </li>
        <li>
          <strong>Technical Information:</strong> Details about your device, including IP address,
          operating system, and usage data, to improve app functionality.
        </li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <ul>
        <li>
          <strong>Attendance Verification:</strong> To authenticate attendance using facial
          recognition and location data.
        </li>
        <li>
          <strong>Employee Records:</strong> To maintain accurate attendance logs accessible to you
          and authorized personnel.
        </li>
        <li>
          <strong>Attendance Reports:</strong> To enable you to view and analyze your attendance
          history.
        </li>
        <li>
          <strong>Service Improvement:</strong> To enhance app features and address technical
          issues.
        </li>
        <li>
          <strong>Compliance:</strong> To meet regulatory requirements or respond to lawful
          requests.
        </li>
      </ul>

      <h2>4. Face Recognition and Biometric Data Handling</h2>
      <p>
        Our app uses face recognition technology to verify employee attendance securely and
        efficiently. The following outlines our approach to collecting, using, storing, and
        protecting biometric data:
      </p>

      <h3>4.1 Data Collection</h3>
      <ul>
        <li>
          We collect facial feature embeddings (mathematical representations of facial features)
          during employee registration.
        </li>
        <li>No raw facial images, videos, or personally identifiable facial data are stored.</li>
        <li>
          Only registered employees with a valid company-assigned person number can sign up and use
          the app.
        </li>
      </ul>

      <h3>4.2 Purpose of Data Usage</h3>
      <ul>
        <li>The collected face data is used exclusively for employee attendance verification.</li>
        <li>
          Employees must be within the required geolocation radius for successful attendance
          marking.
        </li>
        <li>The system ensures that only authorized personnel can access and use the app.</li>
      </ul>

      <h3>4.3 Data Storage & Security</h3>
      <ul>
        <li>
          Biometric data is stored locally on the device or securely on the company’s encrypted
          servers, depending on system configuration.
        </li>
        <li>
          No biometric data is shared with third-party entities, external services, or
          organizations.
        </li>
        <li>
          Industry-standard encryption and security measures protect stored biometric data against
          unauthorized access.
        </li>
      </ul>

      <h3>4.4 Data Retention & Deletion</h3>
      <ul>
        <li>Face data is retained only for the duration of an employee’s active employment.</li>
        <li>
          If an employee leaves the company or requests deletion, their biometric data is
          permanently removed from the system.
        </li>
        <li>Once deleted, biometric data cannot be recovered.</li>
      </ul>

      <h3>4.5 User Rights & Consent</h3>
      <ul>
        <li>
          Employees are informed about the collection and use of their biometric data upon
          registration.
        </li>
        <li>
          Employees can request access to their stored biometric data and request its deletion at
          any time by contacting the company’s HR or IT department.
        </li>
      </ul>

      <h2>5. Data Security</h2>
      <p>
        We use encryption and secure storage to protect your data, but no method of storage is
        completely secure.
      </p>

      <h2>6. Data Retention</h2>
      <p>
        Your information is retained as long as necessary for the purposes outlined in this policy
        or required by law.
      </p>

      <h2>7. Your Rights</h2>
      <ul>
        <li>
          <strong>Access your information:</strong> Request access to your personal and attendance
          data.
        </li>
        <li>
          <strong>Update or correct your details:</strong> Modify your personal information if it is
          inaccurate.
        </li>
        <li>
          <strong>Request deletion of your information:</strong> Request deletion of your data,
          subject to legal retention requirements.
        </li>
        <li>
          <strong>Opt-out:</strong> Opt-out of non-essential communications from us.
        </li>
      </ul>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy periodically. Please review it regularly.</p>

      <h2>9. Contact Us</h2>
      <p>
        If you have questions or concerns, email us at{' '}
        <a href="mailto:admin@babtainattendance.com">admin@babtainattendance.com</a>.
      </p>

      <footer style={{ textAlign: 'center', marginTop: '20px', color: '#666', fontSize: '0.9em' }}>
        &copy; 2024 Babtain Remote Attendance App. All rights reserved.
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
