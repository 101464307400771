import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import { FormControl, FormLabel, Grid, TextField, Typography } from '@mui/material';

function NewProfileCard({ title, description, info, action, shadow }) {
  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });
  Object.values(info).forEach((el) => values.push(el));
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Card sx={{ height: '100%', boxShadow: !shadow && 'none' }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <Typography variant="h5" fontWeight="medium" textTransform="capitalize" color="red">
          {title}
        </Typography>
        {/* <MDTypography component={Link} to={action.route} variant="body2" color="secondary">
          <Tooltip title={action.tooltip} placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </MDTypography> */}
      </MDBox>
      <Grid container spacing={2}>
        {/* Profile Image */}
        <Grid item xs={12} md={10} lg={8} justifyContent="center">
          <MDBox
            p={2}
            sx={{
             
              width: '100%', // Add this
            }}
          >
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  color: 'black',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  marginBottom: 1,
                }}
              >
                Full Name
              </FormLabel>
              <TextField disabled fullWidth required id="outlined-required" value={info?.fullName} />
            </FormControl>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* Profile Image */}
        <Grid item xs={12} md={8} lg={4} justifyContent="center">
          <MDBox
            p={2}
            sx={{
             
              width: '100%', // Add this
            }}
          >
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  color: 'black',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  marginBottom: 1,
                }}
              >
                Email
              </FormLabel>
              <TextField disabled fullWidth required id="outlined-required" value={info?.email} />
            </FormControl>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={8} lg={4} justifyContent="center">
          <MDBox
            p={2}
            sx={{
             
              width: '100%', // Add this
            }}
          >
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  color: 'black',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  marginBottom: 1,
                }}
              >
               Person Number
              </FormLabel>
              <TextField disabled fullWidth required id="outlined-required" value={info?.personNumber} />
            </FormControl>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* Profile Image */}
        <Grid item xs={12}md={8} lg={4} justifyContent="center">
          <MDBox
            p={2}
            sx={{
             
              width: '100%', // Add this
            }}
          >
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  color: 'black',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  marginBottom: 1,
                }}
              >
              Role
              </FormLabel>
              <TextField disabled fullWidth required id="outlined-required" value={info?.role} />
            </FormControl>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={8} lg={4} justifyContent="center">
          <MDBox
            p={2}
            sx={{
             
              width: '100%', // Add this
            }}
          >
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  color: 'black',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  marginBottom: 1,
                }}
              >
               Created At
              </FormLabel>
              <TextField disabled fullWidth required id="outlined-required" value={info?.createdAt} />
            </FormControl>
          </MDBox>
        </Grid>
      </Grid>
      </Card>
  );
}
NewProfileCard.defaultProps = {
  shadow: true,
};
NewProfileCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  social: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default NewProfileCard;
