import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDButton from 'components/MDButton';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import { AuthContext } from 'context/AuthContext';
import { DeleteOutline } from '@mui/icons-material';
import { FadeLoader } from 'react-spinners';
import { TextField } from '@mui/material';

function DeclinedUsers() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const { user } = useContext(AuthContext);

  const fetchDeclinedUsers = async () => {
    setLoading(true);
    try {
      const response = await apiService.get('/api/v1/users/declinedUsersList');

      if (response.status === 200) {
        const declinedUsers = response.data.data;

        setColumns([
          { Header: 'Full Name', accessor: 'fullName', align: 'left' },
          { Header: 'Email', accessor: 'email', align: 'left' },
          { Header: 'Personal Number', accessor: 'personalNumber', align: 'left' },
          { Header: 'Department', accessor: 'department', align: 'left' },
          { Header: 'Role', accessor: 'role', align: 'left' },
          { Header: 'Actions', accessor: 'approvalRequest', align: 'center' },
        ]);

        const formattedRows = declinedUsers.map((user) => ({
          id: user._id,
          fullName: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.fullName}
            </MDTypography>
          ),
          email: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.email || 'N/A'}
            </MDTypography>
          ),
          personalNumber: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.personNumber || 'N/A'}
            </MDTypography>
          ),
          department: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.department?.name || 'N/A'}
            </MDTypography>
          ),
          role: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {user.roles.map((el) => el?.name).join(', ') || 'N/A'}
            </MDTypography>
          ),
          approvalRequest: user.isDeleted ? (
            <MDBox display="flex" justifyContent="center" gap={2}>
              <IconButton color="error" onClick={() => openDeleteDialog(user._id)}>
                <DeleteOutline />
              </IconButton>
              <IconButton color="success" onClick={() => handleRestore(user._id)}>
                <RestoreIcon />
              </IconButton>
            </MDBox>
          ) : (
            <MDButton variant="outlined" color="success" onClick={() => handleApplyClick(user._id)}>
              Apply
            </MDButton>
          ),
        }));

        setRows(formattedRows);
        setFilteredRows(formattedRows);
        setLoading(false);
      }
    } catch (error) {
      toast.error('Failed to fetch declined users');
      setLoading(false);
    }
  };

  const openDeleteDialog = (userId) => {
    setUserToDelete(userId);
    setOpenDialog(true);
  };

  const closeDeleteDialog = () => {
    setUserToDelete(null);
    setOpenDialog(false);
  };

  const handleApplyClick = async (userId) => {
    try {
      const response = await apiService.put(`/api/v1/users/update/${userId}`, {
        declinedStatus: 'notMarked',
      });

      if (response.status === 200) {
        toast.success('Request sent successfully');
        fetchDeclinedUsers();
      }
    } catch (error) {
      toast.error('Failed to update user status');
    }
  };

  const handlePermanentDelete = async () => {
    try {
      const response = await apiService.put(
        `/api/v1/users/delete/${userToDelete}?action=permanent`
      );
      if (response.status === 200) {
        toast.success('User permanently deleted');
        // Remove the deleted user from the rows array directly
        setRows((prevRows) => prevRows.filter((row) => row.id !== userToDelete));
        setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== userToDelete));
        closeDeleteDialog();
      }
    } catch (error) {
      toast.error('Failed to permanently delete user');
      closeDeleteDialog();
    }
  };

  const handleRestore = async (userId) => {
    try {
      const response = await apiService.put(`/api/v1/users/delete/${userId}?action=restore`);
      if (response.status === 200) {
        toast.success('User restored successfully');
        fetchDeclinedUsers();
      }
    } catch (error) {
      toast.error('Failed to restore user');
    }
  };

  useEffect(() => {
    fetchDeclinedUsers();
  }, []);

  const handleSearch = (e) => {
    setLoading(true); // Start loading when search begins

    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    setTimeout(() => {
      const newFilteredRows = rows.filter((row) => {
        const fullName = row.fullName.props.children.props.children?.toLowerCase() || '';
        const personNumber = row.personNumber.props.children?.toLowerCase() || '';
        return fullName.includes(query) || personNumber.includes(query);
      });

      setFilteredRows(newFilteredRows);
      setLoading(false); // Stop loading after filtering is done
    }, 500); // Simulate delay for better UX
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              mb={1}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Blocked Users
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox p={2} display="flex" justifyContent="flex-end">
                <TextField
                  label="Search by Full Name"
                  variant="outlined"
                  placeholder="Search by Full Name"
                  value={searchQuery}
                  onChange={handleSearch}
                  size="medium"
                  fullWidth
                  sx={{ maxWidth: '400px' }}
                />
              </MDBox>
              <MDBox pt={3}>
                {loading ? ( // Show loader while fetching data
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <FadeLoader color="#FF0000" />
                  </MDBox>
                ) : filteredRows.length > 0 ? (
                  <DataTable
                    table={{ columns, rows: filteredRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={true}
                  />
                ) : (
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      No blocked users found
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Dialog open={openDialog} onClose={closeDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to permanently delete this user?</DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePermanentDelete} sx={{ color: 'error.main' }}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default DeclinedUsers;
