import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import bgImage from 'assets/images/background.jpg';
import apiService from '../../../services/ApiService';
import { Box, Button, CircularProgress } from '@mui/material';
import OtpInput from 'react-otp-input';

function Basic() {
  const location = useLocation();
  console.log('location', location);
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  console.log('otp', otp);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let dataToSend = {
      email: location?.state?.email || '',
      otp: otp,
    };
    try {
      const response = await apiService.post('/api/v1/password/verifyOtp', dataToSend);
      console.log('response', response);

      if (response?.status === 200) {
        toast.success(response.data.message);
        navigate('/authentication/reset-password', { state: { email: location?.state?.email } });

        setIsLoading(false);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Invalid Email';
      toast.error(errorMessage);
      setIsLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card
        style={{
          maxWidth: '500px',
          margin: 'auto',
          padding: '40px',
          borderRadius: '16px',
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Box
          py={3}
          variant="gradient"
          bgcolor="#c32033"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="bold" color="white">
            Reset Password
          </MDTypography>
          <MDTypography variant="subtitle1" color="white" mt={1}>
            Enter the 4-digit code sent to your email
          </MDTypography>
        </Box>
        <MDBox pt={4} pb={3} px={5}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={4}>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                separator={
                  <span
                    style={{
                      margin: '0 15px', // Horizontal margin for spacing between input fields
                      padding: '45px', // Optional padding for additional separation
                      fontSize: '24px', // Match the font size of the input fields for consistency
                      fontWeight: 'bold', // Make the separator visually distinct
                    }}
                  >
                    -
                  </span>
                }
                inputStyle={{
                  width: '60px',
                  height: '60px',
                  fontSize: '24px',
                  borderRadius: '8px',
                  border: '1px solid #ddd',
                  textAlign: 'center',
                }}
                focusStyle={{
                  border: '2px solid #007bff',
                }}
              />
            </MDBox>

            <MDBox mt={4}>
              <Button
                style={{
                  backgroundColor: '#c32033',
                  color: 'white',
                  padding: '1rem',
                }}
                disabled={isLoading}
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                // style={{ padding: '15px', fontSize: '18px' }}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Submit OTP'}
              </Button>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
