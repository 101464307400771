import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import AddIcon from '@mui/icons-material/AddBox';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import { FadeLoader } from 'react-spinners';

function Shifts() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchSlotsData = async () => {
    setLoading(true);
    try {
      const response = await apiService.get('/api/v1/shifts/list');
      console.log('response', response);
      if (response.status === 200) {
        const slots = response.data.shifts;

        setColumns([
          { Header: 'Shift Name', accessor: 'shiftName', align: 'left' },
          { Header: 'Shift Type', accessor: 'shiftType', align: 'left' },
          { Header: 'Shift Code', accessor: 'shiftCode', align: 'left' },
          { Header: 'Shift Status', accessor: 'status', align: 'left' },
          { Header: 'Start Time', accessor: 'startTime', align: 'left' },
          { Header: 'End Time', accessor: 'endTime', align: 'left' },
          { Header: 'Action', accessor: 'action', align: 'left' },
        ]);

        const formattedRows = slots.map((slot) => ({
          id: slot._id,
          shiftName: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {slot.shiftName}
            </MDTypography>
          ),
          shiftType: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {slot?.shiftType}
            </MDTypography>
          ),
          shiftCode: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {slot?.shiftCode}
            </MDTypography>
          ),
          status: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {slot?.status}
            </MDTypography>
          ),
          startTime: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {slot?.startTime
                ? new Date(slot.startTime).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                : ''}
            </MDTypography>
          ),
          endTime: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {slot?.endTime
                ? new Date(slot.endTime).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                : ''}
            </MDTypography>
          ),
          action: (
            <MDBox display="flex" alignItems="center" justifyContent="center">
              <Link to={`/shift/edit/${slot._id}`}>
                <Edit fontSize="small" color="info" sx={{ cursor: 'pointer' }} />
              </Link>
              <MDBox mx={1} />
              <DeleteOutline
                fontSize="small"
                color="error"
                sx={{ cursor: 'pointer' }}
                onClick={() => handleDelete(slot._id)}
              />
            </MDBox>
          ),
        }));

        setRows(formattedRows);
        setFilteredRows(formattedRows);
        setLoading(false);
      }
    } catch (error) {
      toast.error('Failed to fetch Slots data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSlotsData();
  }, []);

  const handleDelete = async (slotId) => {
    try {
      const response = await apiService.delete(`/api/v1/shifts/delete/${slotId}`);
      if (response.status === 200) {
        toast.success('Slot deleted successfully');
        setRows((prevRows) => prevRows.filter((row) => row.id !== slotId));
        setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== slotId));
      }
    } catch (error) {
      toast.error('Failed to delete Slot');
    }
  };

  const handleSearch = (e) => {
    setLoading(true); // Start loading when search begins

    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    setTimeout(() => {
      const newFilteredRows = rows.filter((row) => {
        const name = row.shiftName.props?.children?.toLowerCase() || '';
        return name.includes(query);
      });

      setFilteredRows(newFilteredRows);
      setLoading(false); // Stop loading after filtering is done
    }, 500); // Simulate delay for better UX
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              mb={1}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Shift Management
              </MDTypography>
              <Link to={'/shift/create'}>
                <Button
                  variant="contained"
                  style={{ color: 'white', background: ' #c32033' }}
                  startIcon={<AddIcon style={{ color: 'white' }} />}
                >
                  Add New Shift
                </Button>
              </Link>
            </MDBox>
            <Card>
              <MDBox p={2} display="flex" justifyContent="flex-end">
                <TextField
                  label="Search by Shift Name"
                  variant="outlined"
                  placeholder="Search by SHift Name"
                  value={searchQuery}
                  onChange={handleSearch}
                  size="medium"
                  fullWidth
                  sx={{ maxWidth: '400px' }}
                />
              </MDBox>
              <MDBox pt={3}>
                {loading ? ( // Show loader while fetching data
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <FadeLoader color="#FF0000" />
                  </MDBox>
                ) : filteredRows.length > 0 ? (
                  <DataTable
                    table={{ columns, rows: filteredRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={true}
                  />
                ) : (
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      No Shift Found
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Shifts;
