import React, { useState, useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from 'services/ApiService';
import { AuthContext } from 'context/AuthContext';
import moment from 'moment-timezone'; // Make sure this line is present
import TableCustomized from 'components/TableCustomized';
import { Box, CircularProgress } from '@mui/material';
import MDButton from 'components/MDButton';
import FaceWeb from 'components/FaceWeb';
import { FadeLoader } from 'react-spinners';

const timezone = 'Asia/Karachi'; // Set your desired timezone

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
function MarkAttendance() {
  const { user } = useContext(AuthContext);

  const [attendanceLogs, setAttendanceLogs] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showFaceDetection, setShowFaceDetection] = useState(false);
  const [faceImage, setFaceImage] = useState('');
  const [isCheckInDisabled, setIsCheckInDisabled] = useState(false);
  const [isCheckOutDisabled, setIsCheckOutDisabled] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const getAttendanceLogs = async () => {
    if (!user?.email) {
      setMessage('User email not found. Please log in.');
      return;
    }
    try {
      const response = await apiService.get('/api/v1/users/verifyToken');
      console.log('aTTENDANCE response', response);
      if (response.data) {
        setAttendanceLogs(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching attendance logs:', error);
    }
  };

  useEffect(() => {
    getAttendanceLogs();
  }, [user]);

  // Helper function to get the user's current location (geolocation)
  const getCurrentLocation = () =>
    new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ lat: latitude, lng: longitude });
          },
          (error) => {
            reject(error.message);
          }
        );
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });

  // const handleAttendance = async (status) => {
  //   setShowFaceDetection(true);
  //   if (!user?.email) {
  //     setMessage('User email not found. Please log in.');
  //     return;
  //   }

  //   // Update button states based on the clicked button
  //   if (status === 'checkIn') {
  //     setIsCheckInDisabled(false);
  //     setIsCheckOutDisabled(true);
  //   } else if (status === 'checkOut') {
  //     setIsCheckOutDisabled(false);
  //     setIsCheckInDisabled(true);
  //   }

  //   if (faceImage) {
  //     setLoading(true);
  //     setMessage('');

  //     try {
  //       const currentLocation = await getCurrentLocation();

  //       const response = await apiService.post('/api/v1/users/markAttendance', {
  //         email: user.email,
  //         status: status,
  //         currentLocation,
  //       });

  //       if (response.data.success) {
  //         setIsSuccess(true);
  //         setMessage(response.data.message);
  //         await getAttendanceLogs();
  //       } else {
  //         setIsSuccess(false);
  //         setMessage(response.data.message || 'Failed to mark attendance.');
  //       }
  //     } catch (error) {
  //       if (error.response && error.response.data.message) {
  //         setMessage(error.response.data.message);
  //       } else {
  //         setMessage('An error occurred while marking attendance.');
  //       }
  //     }

  //     setLoading(false);
  //   }
  // };

  const handleAttendance = async (status) => {
    console.log('handleAttendance');
    setShowFaceDetection(true);

    if (!user?.email) {
      setMessage('User email not found. Please log in.');
      return;
    }

    // Update button states based on the clicked button
    if (status === 'checkIn') {
      setIsCheckInDisabled(false);
      setIsCheckOutDisabled(true);
    } else if (status === 'checkOut') {
      setIsCheckOutDisabled(false);
      setIsCheckInDisabled(true);
    }

    if (faceImage) {
      setLoading(true);
      setMessage('');

      try {
        const currentLocation = await getCurrentLocation();

        // Prepare FormData
        const formData = new FormData();
        formData.append('email', user.email);
        formData.append('status', status);
        formData.append('currentLocation', JSON.stringify(currentLocation));

        const file = dataURLtoFile(faceImage, 'capturedFace.jpg');
        formData.append('faceImage', file);
        // formData.append('faceImage', faceImage); // Ensure `faceImage` is a File object

        const response = await apiService.post('/api/v1/users/markAttendance', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.success) {
          setIsSuccess(true);
          setMessage(response.data.message);
          await getAttendanceLogs();
          setLoading(false);
          setFaceImage(''); // Reset faceImage state
          setIsCheckInDisabled(false);
          setIsCheckOutDisabled(false);
        } else {
          setIsSuccess(false);
          setMessage(response.data.message || 'Failed to mark attendance.');
        }
      } catch (error) {
        if (error.response && error.response.data.message) {
          setMessage(error.response.data.message);
        } else {
          setMessage('An error occurred while marking attendance.');
        }
      }

      setLoading(false);
    }
    //  else {
    //   setMessage('Please provide a face image for attendance.');
    // }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Mark Your Attendance
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox pt={3} px={3} pb={3}>
                {isSuccess ? (
                  <MDBox>
                    <MDTypography variant="h6" color="success">
                      {message}
                    </MDTypography>
                  </MDBox>
                ) : (
                  <MDTypography variant="h6" color="error">
                    {message}
                  </MDTypography>
                )}
                <Box
                  style={{
                    marginBottom: '20px',
                  }}
                >
                  {showFaceDetection && (
                    <FaceWeb
                      setFaceImage={setFaceImage}
                      isShown={isShown}
                      setIsShown={setIsShown}
                      isReset={true}
                    />
                  )}
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#c32033',
                        color: 'white',
                        padding: '1rem',
                      }}
                      onClick={() => handleAttendance('checkIn')}
                      disabled={isCheckInDisabled}
                      fullWidth
                    >
                      {loading ? <CircularProgress color="white" size={'25px'} /> : 'Check In'}
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: '#c32033',
                        color: 'white',
                        padding: '1rem',
                      }}
                      onClick={() => handleAttendance('checkOut')}
                      disabled={isCheckOutDisabled}
                      fullWidth
                    >
                      {loading ? <CircularProgress color="white" size={'25px'} /> : 'Check Out'}
                    </Button>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            {loading ? (
              <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                <FadeLoader color="#FF0000" />
              </MDBox>
            ) : (
              <MDBox mt={3}>
                <TableCustomized attendanceLogs={attendanceLogs} />
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default MarkAttendance;
