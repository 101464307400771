// import * as React from 'react';
// import { styled } from '@mui/material/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import MDButton from 'components/MDButton';
// import { Box } from '@mui/material';
// import dayjs from 'dayjs';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     // backgroundColor: 'linear-gradient(195deg, #42424a, #191919)',
//     backgroundColor: '#c32033',
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
// }));

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [createData('Frozen yoghurt', 159)];

// export default function StatusRecord({ attendanceLogs }) {
//   console.log('attendanceLogs', attendanceLogs);
//   return (
//     <Box
//       sx={{
//         mt: 10,
//       }}
//     >
//       <TableContainer
//         sx={{
//           // border: '1px solid #42424a',
//           padding: '10px',
//         }}
//         component={Paper}
//       >
//         <Box
//           sx={{
//             display: 'flex',
//             justifyContent: 'flex-end',
//             mb: 2,
//           }}
//         >
//           {/* Add any buttons here */}
//         </Box>

//         <Table sx={{ minWidth: 400 }} aria-label="customized table">
//           <TableHead
//             sx={{
//               display: 'contents',
//               width: '100%',
//             }}
//           >
//             <TableRow>
//               <StyledTableCell align="center">Emp No</StyledTableCell>
//               <StyledTableCell align="center"> Name</StyledTableCell>
//               <StyledTableCell align="center">Date</StyledTableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {attendanceLogs?.length > 0 ? (
//               attendanceLogs.map((row, index) => (
//                 <StyledTableRow key={`${index}-${row.date}`}>
//                   <StyledTableCell align="center">{row?.personNumber || 'N/A'}</StyledTableCell>
//                   <StyledTableCell align="center">{row?.name || 'N/A'}</StyledTableCell>
//                   {/* Date */}
//                   <StyledTableCell align="center">
//                     {dayjs(row.date).format('DD-MM-YYYY')}
//                   </StyledTableCell>
//                   {/* User Details */}
//                 </StyledTableRow>
//               ))
//             ) : (
//               <StyledTableRow>
//                 <StyledTableCell align="center" colSpan={3}>
//                   No attendance logs available
//                 </StyledTableCell>
//               </StyledTableRow>
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   );
// }

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import MDButton from 'components/MDButton';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#c32033',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function StatusRecord({ attendanceLogs }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ExportAttendanceLogs = () => {
    const headers = ['Emp No.', 'Name', 'Department', 'Date'];
    const rows = attendanceLogs?.map((user) => [
      user.personNumber ?? 'N/A',
      user.name ?? 'N/A',
      user.department ?? 'N/A',
      dayjs(user.date).format('YYYY-MM-DD'),
    ]) || [];

    const csvContent = [headers, ...rows].map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'attendance_logs.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Box sx={{ mt: 10 }}>
      <TableContainer sx={{ padding: '10px' }} component={Paper}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <MDButton onClick={ExportAttendanceLogs} variant="contained" color="success">
            Download Attendance Logs
          </MDButton>
        </Box>
        <Table sx={{ minWidth: 400 }} aria-label="customized table">
          <TableHead
            sx={{
              display: 'contents',
              width: '100%',
            }}
          >
            <TableRow>
              <StyledTableCell align="center">Emp No</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Department</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceLogs?.length > 0 ? (
              attendanceLogs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={`${index}-${row.date}`}>
                    <StyledTableCell align="center">{row?.personNumber || 'N/A'}</StyledTableCell>
                    <StyledTableCell align="center">{row?.name || 'N/A'}</StyledTableCell>
                    <StyledTableCell align="center">{row?.department || 'N/A'}</StyledTableCell>
                    <StyledTableCell align="center">
                      {dayjs(row.date).format('DD-MM-YYYY')}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            ) : (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={3}>
                  No attendance logs available
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={attendanceLogs?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
