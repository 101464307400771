import { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from '../../services/ApiService'; // Update the path according to your project structure
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';
import { Button } from '@mui/material';

function EditUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { permission } = useContext(AuthContext);
  const { Admin, HrManager, HRBP } = permission; // Check permissions for roles

  const [formData, setFormData] = useState({
    // firstName: "",
    // lastName: "",
    // employeeNo: "",
    fullName: '',
    personNumber: '',
    // departmentId: "",
    // companyId: "",
    // businessUnitId: "",
    email: '',
    password: '',
    // role: "Employee", // Default role
  });

  // Fetch user data on component mount
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiService.get(`/api/v1/users/detail/${id}`);
        console.log('hello');
        const user = response?.data?.data?.user; // Assuming the response structure
        // Set the form data with proper field names
        setFormData({
          fullName: user.fullName || '',
          personNumber: user.personNumber || '',
          // firstName: user.firstName || "",
          // lastName: user.lastName || "",
          // employeeNo: user.employeeNo || "",
          // departmentId: user.department?._id || "", // Use _id from department
          // companyId: user.company?._id || "", // Use _id from company
          // businessUnitId: user.businessUnit?._id || "", // Use _id from businessUnit
          email: user.email || '',
          // role: user.roles?.[0]?.name || "Employee", // Use the first role from roles array
        });
      } catch (error) {
        toast.error('Failed to fetch user data');
      }
    };

    fetchUser();
  }, [id]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedData = { ...formData };

    // Remove the password field if it's empty (i.e., if the user didn't change it)
    if (!formData.password) {
      delete updatedData.password;
    }

    try {
      const response = await apiService.put(`/api/v1/users/update/${id}`, updatedData);
      console.log('response', response);
      if (response.status === 200) {
        navigate('/users');
        toast.success('User updated successfully');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to update user');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Edit User
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={6} mt={-3}>
            <Card>
              <MDTypography pt={3} px={2} variant="h6" color="grey.700" fontWeight="medium">
                Edit User
              </MDTypography>

              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Full Name"
                    name="fullName"
                    variant="outlined"
                    fullWidth
                    value={formData.fullName}
                    onChange={handleInputChange}
                  />
                </MDBox>

                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Person Number"
                    name="personNumber"
                    variant="outlined"
                    fullWidth
                    value={formData.personNumber}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    name="email"
                    variant="outlined"
                    fullWidth
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </MDBox>
                {Admin && (
                  <>
                    <MDBox mb={2}>
                      <MDInput
                        type="password"
                        label="Password"
                        name="password"
                        variant="outlined"
                        fullWidth
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                    </MDBox>
                  </>
                )}

                <MDBox mt={4} mb={1}>
                  <Button
                    style={{
                      backgroundColor: '#c32033',
                      color: 'white',
                      padding: '1rem',
                    }}
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default EditUser;
