import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import AddIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import apiService from '../../services/ApiService';
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { Button, TextField } from '@mui/material';
import { FadeLoader } from 'react-spinners';

function Tables() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchBusinessUnitsData = async () => {
    setLoading(true);
    try {
      const response = await apiService.get('/api/v1/businessUnits/list');
      if (response.status === 200) {
        const businessUnits = response.data.businessUnits;

        setColumns([
          { Header: 'Name', accessor: 'name', width: '35%', align: 'left' },
          { Header: 'Company', accessor: 'company', align: 'left' },
          { Header: 'Action', accessor: 'action', align: 'left' },
        ]);

        const formattedRows = businessUnits.map((unit) => ({
          id: unit._id,
          name: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {unit.name}
            </MDTypography>
          ),
          company: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {unit.company ? unit.company.name : 'N/A'}
            </MDTypography>
          ),
          action: (
            <MDBox display="flex" alignItems="center" justifyContent="center">
              <Link to={`/business/edit/${unit._id}`}>
                <Edit fontSize="small" color="info" sx={{ cursor: 'pointer' }} />
              </Link>
              <MDBox mx={1} />
              <DeleteOutline
                fontSize="small"
                color="error"
                sx={{ cursor: 'pointer' }}
                onClick={() => handleDelete(unit._id)}
              />
            </MDBox>
          ),
        }));

        setRows(formattedRows);
        setFilteredRows(formattedRows);
        setLoading(false);
      }
    } catch (error) {
      toast.error('Failed to fetch business units data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBusinessUnitsData();
  }, []);

  const handleDelete = async (businessUnitId) => {
    try {
      const response = await apiService.delete(`/api/v1/businessUnits/delete/${businessUnitId}`);
      if (response.status === 200) {
        toast.success('BusinessUnit deleted successfully');
        setRows((prevRows) => prevRows.filter((row) => row.id !== businessUnitId));
        setFilteredRows((prevRows) => prevRows.filter((row) => row.id !== businessUnitId));
      }
    } catch (error) {
      toast.error('Failed to delete business Unit');
    }
  };

  const handleSearch = (e) => {
    setLoading(true);

    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    setTimeout(() => {
      const newFilteredRows = rows.filter((row) => {
        const name = row.name.props?.children?.toLowerCase() || '';
        return name.includes(query);
      });

      setFilteredRows(newFilteredRows);
      setLoading(false);
    }, 500);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              mb={1}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Business Units
              </MDTypography>
              <Link to={'/business/create'}>
                <Button
                  variant="contained"
                  style={{ color: 'white', background: ' #c32033' }}
                  startIcon={<AddIcon style={{ color: 'white' }} />}
                >
                  Add New Business Unit
                </Button>
              </Link>
            </MDBox>
            <Card>
              <MDBox p={2} display="flex" justifyContent="flex-end">
                <TextField
                  label='Search by Business Unit "Name"'
                  variant="outlined"
                  placeholder='Search by Business Unit "Name"'
                  value={searchQuery}
                  onChange={handleSearch}
                  size="medium"
                  fullWidth
                  sx={{ maxWidth: '400px' }}
                />
              </MDBox>
              <MDBox pt={3}>
                {loading ? ( // Show loader while fetching data
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <FadeLoader color="#FF0000" />
                  </MDBox>
                ) : filteredRows.length > 0 ? (
                  <DataTable
                    table={{ columns, rows: filteredRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={true}
                  />
                ) : (
                  <MDBox display="flex" alignItems="center" justifyContent="center" p={3}>
                    <MDTypography variant="caption" color="text" fontWeight="medium">
                      No Business Unit Found
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
