// import * as React from 'react';
// import { styled } from '@mui/material/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import MDButton from 'components/MDButton';
// import { Box } from '@mui/material';
// import dayjs from 'dayjs';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     // backgroundColor: 'linear-gradient(195deg, #42424a, #191919)',
//     backgroundColor: '#c32033',
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
// }));

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [createData('Frozen yoghurt', 159)];

// export default function AdminCustomized({ attendanceLogs }) {
//   console.log('attendanceLogs', attendanceLogs);
//   const ExportAttendanceLogs = () => {
//     const headers = ['Emp No.', 'Name', 'Department', 'Date', 'Shift', 'Time', 'Status'];

//     const rows = attendanceLogs?.report?.flatMap((user) => {
//       if (user.attendanceRecords.length > 0) {
//         return user.attendanceRecords.map((record, index) => [
//           user.user.personNumber, // Emp No.
//           index === 0 ? user.user.name : '', // Show name only for the first record
//           user.department, // Department
//           `${dayjs(record.date).format('YYYY-MM-DD')}\t`, // Date (with tab to fix Excel format)
//           user.shift.shiftName, // Shift
//           record.time, // Time
//           record.status === 'checkIn' ? 'Checked In' : 'Checked Out', // Status
//         ]);
//       } else {
//         return [
//           [
//             user.user.personNumber, // Emp No.
//             user.user.name, // Name
//             user.department, // Department
//             'N/A\t', // Date
//             user.shift.shiftName, // Shift
//             'N/A', // Time
//             'N/A', // Status
//           ],
//         ];
//       }
//     });

//     const csvContent = [headers, ...rows].map((row) => row.join(',')).join('\n');

//     const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', 'attendance_logs.csv');
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   return (
//     <Box
//       sx={{
//         mt: 10,
//       }}
//     >
//       <TableContainer
//         sx={{
//           // border: '1px solid rgb(86, 86, 93)',
//           padding: '10px',
//         }}
//         component={Paper}
//       >
//         <Box
//           sx={{
//             display: 'flex',
//             justifyContent: 'flex-end',
//             mb: 2,
//           }}
//         >
//           <MDButton onClick={ExportAttendanceLogs} variant="contained"  color="success">
//             Download Attendance Logs
//           </MDButton>
//         </Box>

//         <Table sx={{ minWidth: 400 }} aria-label="customized table">
//           <TableHead
//             sx={{
//               display: 'contents',
//               width: '100%',
//             }}
//           >
//             <TableRow>
//               <StyledTableCell align="center">Emp No.</StyledTableCell>
//               <StyledTableCell align="center">Name</StyledTableCell>
//               <StyledTableCell align="center">Department</StyledTableCell>
//               <StyledTableCell align="center">Date</StyledTableCell>
//               <StyledTableCell align="center">Shift</StyledTableCell>
//               <StyledTableCell align="center">Time</StyledTableCell>
//               <StyledTableCell align="center">Status</StyledTableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {attendanceLogs?.report?.map((row, index) => (
//               <React.Fragment key={index}>
//                 {row?.attendanceRecords?.length > 0 ? (
//                   row.attendanceRecords.map((item, idx) => (
//                     <StyledTableRow key={`${index}-${idx}`}>
//                       {/* Employee No. */}
//                       <StyledTableCell align="center">{row?.user?.personNumber}</StyledTableCell>
//                       {/* Employee Name */}
//                       <StyledTableCell align="center">{row?.user?.name}</StyledTableCell>
//                       {/* Department */}
//                       <StyledTableCell align="center">{row?.department || 'N/A'}</StyledTableCell>
//                       {/* Attendance Record Details */}
//                       <StyledTableCell align="center">{item.date}</StyledTableCell>
//                       {/* Shift Name */}
//                       <StyledTableCell align="center">
//                         {row?.shift?.shiftName || 'N/A'}
//                       </StyledTableCell>
//                       <StyledTableCell align="center">{item.time}</StyledTableCell>
//                       <StyledTableCell align="center">
//                         {item.status === 'checkIn' ? 'Checked In' : 'Checked Out'}
//                       </StyledTableCell>
//                     </StyledTableRow>
//                   ))
//                 ) : (
//                   <StyledTableRow>
//                     {/* No Attendance Record */}
//                     <StyledTableCell align="center">{row?.user?.personNumber}</StyledTableCell>
//                     <StyledTableCell align="center">{row?.user?.name}</StyledTableCell>
//                     <StyledTableCell align="center">{row?.department || 'N/A'}</StyledTableCell>
//                     <StyledTableCell align="center">N/A</StyledTableCell>
//                     <StyledTableCell align="center">
//                       {row?.shift?.shiftName || 'N/A'}
//                     </StyledTableCell>
//                     <StyledTableCell align="center">N/A</StyledTableCell>
//                     <StyledTableCell align="center">N/A</StyledTableCell>
//                   </StyledTableRow>
//                 )}

//                 {/* Separator Line after Each User */}
//                 <StyledTableCell
//                   colSpan={7}
//                   align="center"
//                   style={{
//                     borderTop: '2px solid #ccc',
//                     padding: 0,
//                     background: '#ccc',
//                     height: '10px',
//                   }}
//                 />
//               </React.Fragment>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Box>
//   );
// }

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import MDButton from 'components/MDButton';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#c32033',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function AdminCustomized({ attendanceLogs }) {
  console.log('attendanceLogs', attendanceLogs);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ExportAttendanceLogs = () => {
    const headers = ['Emp No.', 'Name', 'Department', 'Date', 'Shift', 'Time', 'Status'];

    const rows = attendanceLogs?.report?.flatMap((user) => {
      if (user.attendanceRecords.length > 0) {
        return user.attendanceRecords.map((record, index) => [
          user.user.personNumber, // Emp No.
          user.user.name, // Show name only for the first record
          user.department, // Department
          `${dayjs(record.date).format('YYYY-MM-DD')}\t`, // Date (with tab to fix Excel format)
          user.shift.shiftName, // Shift
          record.time, // Time
          record.status === 'checkIn' ? 'Checked In' : 'Checked Out', // Status
        ]);
      } else {
        return [
          [
            user.user.personNumber, // Emp No.
            user.user.name, // Name
            user.department, // Department
            'N/A\t', // Date
            user.shift.shiftName, // Shift
            'N/A', // Time
            'N/A', // Status
          ],
        ];
      }
    });

    const csvContent = [headers, ...rows].map((row) => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'attendance_logs.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const paginatedRows = attendanceLogs?.report?.slice(
  //   page * rowsPerPage,
  //   page * rowsPerPage + rowsPerPage
  // );

  const flattenedRows = attendanceLogs?.report?.flatMap((user) =>
    user.attendanceRecords.length > 0
      ? user.attendanceRecords.map((record) => ({
          empNo: user.user.personNumber,
          name: user.user.name,
          department: user.department,
          date: dayjs(record.date).format('YYYY-MM-DD'),
          shift: user.shift.shiftName,
          time: record.time,
          status: record.status === 'checkIn' ? 'Checked In' : 'Checked Out',
        }))
      : [
          {
            empNo: user.user.personNumber,
            name: user.user.name,
            department: user.department,
            date: 'N/A',
            shift: user.shift.shiftName,
            time: 'N/A',
            status: 'N/A',
          },
        ]
  );

  console.log('flattenedRows', flattenedRows);

  // Apply pagination correctly
  const paginatedRows = flattenedRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  console.log('paginatedRows', paginatedRows);

  return (
    <Box sx={{ mt: 10 }}>
      <TableContainer sx={{ padding: '10px' }} component={Paper}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <MDButton onClick={ExportAttendanceLogs} variant="contained" color="success">
            Download Attendance Logs
          </MDButton>
        </Box>

        <Table sx={{ minWidth: 400 }} aria-label="customized table">
          <TableHead
            sx={{
              display: 'contents',
              width: '100%',
            }}
          >
            <TableRow>
              <StyledTableCell align="center">Emp No.</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Department</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">Shift</StyledTableCell>
              <StyledTableCell align="center">Time</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {paginatedRows?.map((row, index) => (
              <React.Fragment key={index}>
                {row?.attendanceRecords?.length > 0 ? (
                  row.attendanceRecords.map((item, idx) => (
                    <StyledTableRow key={`${index}-${idx}`}>
                      <StyledTableCell align="center">{row?.user?.personNumber}</StyledTableCell>
                      <StyledTableCell align="center">{row?.user?.name}</StyledTableCell>
                      <StyledTableCell align="center">{row?.department || 'N/A'}</StyledTableCell>
                      <StyledTableCell align="center">
                        {dayjs(item.date).format('YYYY-MM-DD')}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.shift?.shiftName || 'N/A'}
                      </StyledTableCell>
                      <StyledTableCell align="center">{item.time}</StyledTableCell>
                      <StyledTableCell align="center">
                        {item.status === 'checkIn' ? 'Checked In' : 'Checked Out'}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell align="center">{row?.user?.personNumber}</StyledTableCell>
                    <StyledTableCell align="center">{row?.user?.name}</StyledTableCell>
                    <StyledTableCell align="center">{row?.department || 'N/A'}</StyledTableCell>
                    <StyledTableCell align="center">N/A</StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.shift?.shiftName || 'N/A'}
                    </StyledTableCell>
                    <StyledTableCell align="center">N/A</StyledTableCell>
                    <StyledTableCell align="center">N/A</StyledTableCell>
                  </StyledTableRow>
                )}
              </React.Fragment>
            ))} */}
            {paginatedRows?.length === 0 || paginatedRows === undefined ? (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={7}>
                  No Logs Found
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              paginatedRows?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">{row.empNo}</StyledTableCell>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">{row.department}</StyledTableCell>
                  <StyledTableCell align="center">{row.date}</StyledTableCell>
                  <StyledTableCell align="center">{row.shift}</StyledTableCell>
                  <StyledTableCell align="center">{row.time}</StyledTableCell>
                  <StyledTableCell align="center">{row.status}</StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>

        {/* Pagination Controls */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={attendanceLogs?.report?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}
