import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from '../../services/ApiService'; // Update the path according to your project structure
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

function Edit() {
  const { id } = useParams(); // Get the company ID from the URL params
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    // address: "",
    // country: "",
    // city: "",
    // state: "",
    // taxReportingNumber: "",
    // legalReportingNumber: "",
    // legalEntityNumber: "",
  });

  // Fetch company data on component mount
  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await apiService.get(`/api/v1/companies/detail/${id}`);
        const company = response.data.company; // Assuming the response structure
        setFormData({
          name: company.name || '',
          // address: company.address || "",
          // country: company.country || "",
          // city: company.city || "",
          // state: company.state || "",
          // taxReportingNumber: company.taxReportingNumber || "",
          // legalReportingNumber: company.legalReportingNumber || "",
          // legalEntityNumber: company.legalEntityNumber || "",
        });
      } catch (error) {
        toast.error('Failed to fetch company data');
      }
    };

    fetchCompany();
  }, [id]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.put(`/api/v1/companies/update/${id}`, formData);
      if (response.status === 200) {
        navigate('/companies');
        toast.success('Company updated successfully');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to update company');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Edit Company
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={6} mt={-3}>
            <Card>
              <MDTypography pt={3} px={2} variant="h6" color="grey.700" fontWeight="medium">
                Edit Company
              </MDTypography>
              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Company Name"
                    name="name"
                    variant="outlined"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </MDBox>

                <MDBox mt={4} mb={1}>
                  <Button
                    style={{
                      backgroundColor: '#c32033',
                      color: 'white',
                      padding: '1rem',
                    }}
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Edit;
