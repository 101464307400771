// /**
// =========================================================
// * Material Dashboard 2 React - v2.2.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/material-dashboard-react
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// // prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";

// function DataTableBodyCell({ noBorder, align, children }) {
//   return (
//     <MDBox
//       component="td"
//       textAlign={align}
//       py={1.5}
//       px={3}
//       sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
//         fontSize: size.sm,
//         borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
//       })}
//     >
//       <MDBox
//         display="inline-block"
//         width="max-content"
//         color="text"
//         sx={{ verticalAlign: "middle" }}
//       >
//         {children}
//       </MDBox>
//     </MDBox>
//   );
// }

// // Setting default values for the props of DataTableBodyCell
// DataTableBodyCell.defaultProps = {
//   noBorder: false,
//   align: "left",
// };

// // Typechecking props for the DataTableBodyCell
// DataTableBodyCell.propTypes = {
//   children: PropTypes.node.isRequired,
//   noBorder: PropTypes.bool,
//   align: PropTypes.oneOf(["left", "right", "center"]),
// };

// export default DataTableBodyCell;

import PropTypes from 'prop-types';
import MDBox from 'components/MDBox';
import { whitespace } from 'stylis';

function DataTableBodyCell({ noBorder, align, children }) {
  return (
    <MDBox
      component="td"
      textAlign={align}
      py={2}
      px={4}
      sx={({ palette, typography, borders }) => ({
        fontSize: typography.body2.fontSize,
        fontWeight: typography.fontWeightMedium,
        borderBottom: noBorder ? 'none' : `${borders.borderWidth[1]} solid ${palette.grey[300]}`,
        backgroundColor: palette.background.paper,
        transition: 'all 0.3s ease-in-out',
        whiteSpace: 'nowrap',
        '&:hover': {
          backgroundColor: palette.grey[100],
        },
      })}
    >
      <MDBox display="flex" alignItems="center" color="text.primary">
        {children}
      </MDBox>
    </MDBox>
  );
}

DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: 'left',
};

DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

export default DataTableBodyCell;
