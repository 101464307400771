import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import apiService from '../../services/ApiService'; // Import your API service
import { toast } from 'react-hot-toast'; // Import toast for notifications
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

function CreateRole() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
  });

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.post('/api/v1/roles/create', formData);
      if (response.status === 201) {
        navigate('/roles');
        toast.success(response.data.message);
        setFormData({
          name: '',
        });
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to create role');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <MDTypography variant="h3" color="grey.700" fontWeight="medium">
                Create Role
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={6} mt={-3}>
            <Card>
              <MDTypography pt={3} px={2} variant="h6" color="grey.700" fontWeight="medium">
                Create Role
              </MDTypography>
              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Role Name"
                    name="name"
                    variant="outlined"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <Button
                    style={{
                      backgroundColor: '#c32033',
                      color: 'white',
                      padding: '1rem',
                    }}
                    variant="gradient"
                    color="info"
                    type="submit"
                  >
                    Create
                  </Button>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateRole;
